import { splitInTwo } from 'shared/util'
import EventApi from './event-api'
import ParkApi from './park-api'
import ClubApi from './club-api'
import RetailApi from './retail-api'

class ApiDispatcher {
  async loadModel(modelId) {
    if (modelId == null) return null
    let [type, id] = splitInTwo(modelId, ':')

    if (type == 'event:occurrence') return EventApi.show(id)
    if (type == 'event') return EventApi.show(id)
    if (type == 'park') return ParkApi.show(id)
    if (type == 'club') return ClubApi.show(id)
    if (type == 'retail') return RetailApi.show(id)
  }
}

export default new ApiDispatcher
