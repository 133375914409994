import { withErrorBoundary, Formatter } from 'shared'
import { SmartButtonStub } from 'ui/smart-buttons'
import { LogoImage } from 'customizations'
import { User } from 'api'

class MarketplaceSignupPage extends Component
  state: { user: { email: '', phone: '', first_name: '', last_name: '', password: '', passwordConfirmation: '' } }

  componentDidMount: ->
    @loadData()
    @parseUTM()

    App.signOut redirectPath: '/app#marketplace-signup' if User.signedIn && User.regular

  loadData: ->
    @setState settings: await Backend.get('/users/marketplace_signup_links')

  parseUTM: ->
    params = application.queryParams
    if params && params.has('utm_source')
      @setState user: { @state.user..., utm_source: params.get('utm_source') }
    if params && params.has('retail_id')
      @setState retail_id: params.get('retail_id')

  render: ->
    settings = @state.settings || {}

    pageTitle = settings.page_title
    backgroundColor = settings.color ? '#ccc'
    headImage = settings.head_image_url
    asideImage1 = settings.aside_image_1_url
    asideImage2 = settings.aside_image_2_url

    <div className="Login Signup">
      <div className="left d-none d-sm-block">
        <div className="left-overlay"></div>
        <p className="login-copyright">{application.assets.get('copyright')}</p>
      </div>
      <div className="right">
        <img src={LogoImage} className='logo-image mb-2' />
        <section className="form">
          <h3 className="mb-3 d-none">Sign Up</h3>

          { if @state.success then @renderSuccess() else @renderForm() }

          {
            unless App.isBigSky
              <p className="links text-center my-2">
                <Link to="login" className="smart-button btn inverted primary">
                  <span className="smart-button-text">Return to Sign In</span>
                </Link>
              </p>
          }
        </section>
      </div>
    </div>

  renderSuccess: ->
    <p className="alert alert-success text-center">
      {
        
        "Thank you for registering!"
      }
    </p>

  renderForm: ->
    settings = @state.settings || {}

    <form className="signup--form" onSubmit={@submit}>
      <p dangerouslySetInnerHTML={ __html: Formatter.simpleFormat(settings.welcome_text) }></p>
      {
        if @state.errors
          for error in @state.errors
            <p key={error} className="alert alert-danger alert-mini text-center">{error}</p>
      }
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--first-name">First Name</label>
            <input type="text" className="form-control" id="signup--first-name" name="first_name" value={@state.first_name} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--last-name">Last Name</label>
            <input type="text" className="form-control" id="signup--last-name" name="last_name" value={@state.last_name} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--email">Email Address</label>
            <input type="email" className="form-control" id="signup--email" name="email" value={@state.email} onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label htmlFor="signup--phone">Phone Number</label>
            <input type="tel" className="form-control" id="signup--phone" name="phone" value={@state.phone} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--password">Password</label>
            <input type="password" className="form-control" id="signup--password" name="password" value={@state.password} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--passwordConfirmation">Confirm Password</label>
            <input type="password" className="form-control" id="signup--passwordConfirmation" name="confirm_password" value={@state.passwordConfirmation} onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="form-actions text-center">
        <SmartButtonStub text="Sign Up" buttonType="submit" className="inverted primary" spinning={@state.submitting} />
      </div>
    </form>


  inputChanged: (e) =>
    { name, value } = e.target
    @setState user: { @state.user..., "#{name}": value }, errors: null

  submit: (e) =>
    @setState submitting: true
    e.preventDefault()
    console.log(@state)
    try
      res = await Backend.post '/users/create_marketplace_signup', { user: @state.user, retail_id: @state.retail_id }
      console.info('auth as', res.user)
      User.signIn(res.user)
      @setState errors: null, success: true
      window.location = '/app'
    catch xhr
      if xhr.status >= 400 && xhr.status <= 499
        @setState errors: xhr.responseJSON.errors
    finally
      @setState submitting: false

export default withErrorBoundary(MarketplaceSignupPage)
