import { ClubApi } from 'api'
import { withErrorBoundary, connected } from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'

class Group extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
  }

  render() {
    let id = this.props.match.params.id
    let models = ClubApi.kick('joined')

    return (
      <TopLevelPage className="Parks" modelId={id} base='/groups' pageTitle={"My Clubs and Groups"}>
        <ModelGrid models={models} base={'/groups'} />
      </TopLevelPage>
    )
  }
}

export default withErrorBoundary(connected(Group))