import { withErrorBoundary } from 'shared'
import { Backend }           from 'base'
import { TopLevelPage }      from 'ui'
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements'

// window.STRIPE_PUBLIC_KEY = process.env.STRIPE_SANDBOX_PUBLIC_KEY

class StripeCardEditor extends Component {
  constructor() {
    super()
    this.state = { stripe: null }
  }

  componentDidMount() {
    if (!document.querySelector('#stripe-js')) {
      let script = document.createElement('script')
      script.src = "https://js.stripe.com/v3/"
      script.onload = this.stripeDidLoad
      document.head.appendChild(script)
    } else {
      if (window.Stripe)
        this.stripeDidLoad()
      else
        document.querySelector('#stripe-js').addEventListener('load', this.stripeDidLoad)
    }
  }

  stripeDidLoad = () => {
    console.log('loaded', App.stripePublicKey)
    this.setState({ stripe: window.Stripe(App.stripePublicKey) })
  }

  render() {
    return (
      <TopLevelPage className="Payment row">
      {
        this.state.stripe &&
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <CardForm />
          </Elements>
        </StripeProvider>
      }
      </TopLevelPage>
    )
  }
}


class _CardForm extends Component {
  state = { success: null, saving: null }

  componentWillUnmount() {
    delete App.state.objects.interruptedReservation
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ saving: true })
    try {
      let { source, error } = await this.props.stripe.createSource({ type: 'card' })
      console.info('Stripe result:', source, error)
      if (error) {
        alert(error.message || 'Unexpected card error!')
        return
      }
      let apiResult = await Backend.post("/profile/credit_cards", { nonce: source.id })
      console.info('API result:', apiResult)
      localStorage.rlHasCard = true
      this.setState({ success: true, saving: false })
      this.forceUpdate()
    } catch (err) {
      console.warn("Stripe or API error", err)
      this.setState({ success: false, saving: false })
    } finally {
      this.setState({ saving: false })
    }

    // e.preventDefault()
    // this.setState({ saving: true })
    // let success = PaymentSettingsController.addStripeCard(stripeElement)
    // this.setState({ success, saving: false })
  }

  render() {
    let { success, saving } = this.state
    let { interruptedReservation = {} } = App.state.objects
    let cardRedirectPath = interruptedReservation.path

    if (success)
      return <Redirect to="/settings/payment" />

    return (
      <article className="card-details offset-md-2 col-md-8">
        {cardRedirectPath && !success &&
          <p className="alert alert-warning text-center">A valid credit card is required to reserve a paid event</p>
        }
        <h2 className="card-details-heading">Enter Your Credit Card</h2>
        {success ?
          <div className="card-details-success">
            <p className="alert alert-success text-center">Your card has been saved!</p>
            <p className="card-details-actions">
              {cardRedirectPath ?
                <Link to={cardRedirectPath} className="btn btn-success">Proceed to Reservation</Link> :
                <Link to="/home" className="btn btn-success">Go back to the app</Link>
              }
            </p>
          </div>
          :
          <form onSubmit={this.handleSubmit}>
            <div className="card-details-content">
              <CardElement style={{base: {fontSize: '18px'}}} />
            </div>
            <p className="card-details-actions">
              <button disabled={saving} className="btn btn-primary btn-block">{saving ? "Saving..." : "Save Card"}</button>
            </p>
          </form>
        }
      </article>
    )
  }
}

const CardForm = injectStripe(_CardForm)

export default withErrorBoundary(StripeCardEditor)
