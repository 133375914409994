import { areEqualOrBlank, presence } from 'shared/util'

export default class Api {
  get state() { return App.state }
  actionKey(action) { return `${this.collectionName}:${action}`}
  cacheEntryFor(action) { return this.state.collections[this.actionKey(action)] }
  paramsFor(action) { return (this.cacheEntryFor(action) || {}).params || {} }

  async get(action, _params, { force = false, actionId = action } = { }) {
    let state = this.state
    let actionKey = this.actionKey(actionId)
    let cacheEntry = this.cacheEntryFor(actionId)
    let params = this.normalizeParams(_params)

    if (force || !cacheEntry || !areEqualOrBlank(cacheEntry.params, params)) {
      if (this.progressStarted(actionId, params)) return
      this.startProgress(actionId, params)
      let results = await this[action].call(this, params)
      this.stopProgress(actionId, params)

      cacheEntry = { params: params }
      state.collections[actionKey] = cacheEntry

      if (results instanceof Array) {
        state.updateModels(results, {trigger: false})
        cacheEntry.type = 'list'
        cacheEntry.data = results.map(model => model.uid)
      } else if (results.groups) {
        state.updateModels(results.models, {trigger: false})
        cacheEntry.type = 'groups'
        cacheEntry.data = _.mapValues(results.groups, models => models.map(model => model.uid))
      }

      state.updated({ scope: actionKey })
    }

    return this.cached(actionId)
  }

  cached(action) {
    let state = this.state
    let cacheEntry = this.cacheEntryFor(action)

    if (cacheEntry == null)
      return null
    if (cacheEntry.type === 'list')
      return cacheEntry.data.map(uid => state.entities[uid])
    if (cacheEntry.type === 'groups')
      return _.mapValues(cacheEntry.data, uids => uids.map(uid => state.entities[uid]))
  }

  // If the list is already in the cache with correct params returns it,
  // otherwise requests it and returns using an event
  kick(action, _params, { force = false, actionId = action } = { }) {
    let params = this.normalizeParams(_params)
    let cacheEntry = this.cacheEntryFor(actionId, params)
    if (cacheEntry && areEqualOrBlank(cacheEntry.params, params))
      return this.cached(actionId)
    else
      this.get(action, params, { force: force, actionId: actionId })
  }

  // Reloads the list event if it is already loaded with the correct params
  // without killing the cache before request
  refresh(action, { actionId = action } = { }) {
    return this.get(action, this.paramsFor(actionId), { force: true, actionId: actionId })
  }

  // Removes the cache for that action
  reset(action, { actionId = action } = { }) {
    let actionKey = this.actionKey(actionId)
    delete this.state.collections[actionKey]
    this.state.updated({ scope: actionKey })
  }

  getWithChangedParamsAsync(action, params) {
    this.get(action, params)
  }

  normalizeParams(params) {
    let normalized = { ...params }
    for (let param in normalized) {
      let value = presence(params[param])
      if (moment.isMoment(value))
        value = value.format('YYYY-MM-DD')
      if (value)
        normalized[param] = value
      else
        delete normalized[param]
    }
    return normalized
  }

  startProgress(action, params) {
    let progresses = this.state.progresses
    let actionKey = this.actionKey(action)
    let progressKey = $.param(params || {})
    progresses[actionKey] = progresses[actionKey] || {}
    progresses[actionKey][progressKey] = true
  }

  stopProgress(action, params) {
    let progresses = this.state.progresses
    let actionKey = this.actionKey(action)
    let progressKey = $.param(params || {})
    delete progresses[actionKey][progressKey]
    if (_.isEmpty(progresses[actionKey]))
      delete progresses[actionKey]
  }

  progressStarted(action, params) {
    let progresses = this.state.progresses
    let actionKey = this.actionKey(action)
    let progressKey = $.param(params || {})
    return !!(progresses[actionKey] && progresses[actionKey][progressKey])
  }

  async convertRemoteError(block) {
    try {
      return await block()
    } catch (xhr) {
      if (xhr.responseJSON) {
        this.state.setError(xhr.responseJSON)
        // return { ...xhr.responseJSON, failure: true }
        return null
      } else {
        console.warn("Unexpected remote error", xhr)
        return null
      }
    }
  }

  addToCollection(list, object) {
    let listKey = this.actionKey(list)
    if (this.state.collections[listKey]) {
      this.state.collections[listKey].data.unshift(object.uid)
      this.state.updated({ scope: listKey })
    }
  }
}
