import React, { Component } from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react/src/main';
import { Link, withRouter } from 'react-router-dom';
import { SmartButtonStub } from 'ui/smart-buttons'


class SeatedEventReservationBlock extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedSeats: {},
      workspaceKey: '',
      seatsioEventId: '',
      ticket_settings: [],
      tickets: {},
      loading: true, // Set loading to true initially
      error: null,
    };
  }

  componentDidMount() {
    const { eventId, token } = this.getParamsFromURL();

    if (eventId && token) {
      this.fetchEventDetails(eventId, token);
    } else {
      this.setState({ 
        error: 'Missing event ID or token in URL.',
        loading: false 
      });
    }
  }

  getParamsFromURL = () => {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    const parts = hash.split('/');

    // Validate URL structure
    if (parts.length < 5 || parts[1] !== 'event' ) {
      return { eventId: null, token: null };
    }

    const eventId = parts[2];
    const token = parts[4];

    return { eventId, token };
  };

  fetchEventDetails = async (eventId, token) => {
    try {
      const response = await Backend.get('/events/fetch_seatsio_deatils', { event_id: eventId, token: token });
      const { event_id, workspace_key, ticket_settings } = response;
      if (event_id && workspace_key) {
        this.setState({ 
          seatsioEventId: event_id, 
          workspaceKey: workspace_key,
          ticket_settings: ticket_settings,
          loading: false 
        });
      } else {
        this.setState({ 
          error: 'Invalid event data received.',
          loading: false 
        });
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
      this.setState({ 
        error: 'Failed to load event details.', 
        loading: false 
      });
    }
  };

  // confirmSeats = () => {
  //   const { eventId, token } = this.getParamsFromURL();
  //   const { selectedSeats, tickets } = this.state;

  //   this.props.history.push(`/event/${eventId}/reserve/${token}/seats:${selectedSeats}/spots:${tickets}`);
  // };

  confirmSeats = () => {
    const { eventId, token } = this.getParamsFromURL();
    const { selectedSeats, tickets } = this.state;

    // Serialize and encode the selectedSeats and tickets objects
    const seatsParam = encodeURIComponent(JSON.stringify(selectedSeats));
    const spotsParam = encodeURIComponent(JSON.stringify(tickets));

    this.props.history.push(`/event/${eventId}/reserve/${token}/seats:${seatsParam}/spots:${spotsParam}`);
  };



  cancelReservation = () => {
    const { eventId, token } = this.getParamsFromURL();

    this.setState({ selectedSeats: {}, tickets:{} });
    // Update the URL to include cancel and event ID
    this.props.history.push(`/event/${eventId}/cancel/${token}/event:${eventId}`);
  };

  onSeatSelected = (seat) => {
    let { tickets, selectedSeats } = this.state;

    // Increment the ticket count for the selected seat's category
    tickets[seat.category.label] = (tickets[seat.category.label] || 0) + 1;

    selectedSeats[seat.category.label] = [...(selectedSeats[seat.category.label] || []), seat.seatId];

    this.setState({
      tickets,
      selectedSeats // Update selectedSeats with the new seat
    });
  }

  onSeatDeselected = (seat) => {
    let { tickets, selectedSeats } = this.state;

    // Decrement the ticket count for the deselected seat's category, ensuring it doesn't go below 0
    if (tickets[seat.category.label] > 0) {
      tickets[seat.category.label] -= 1;

      // If the count becomes zero, remove the ticket category from the tickets object
      if (tickets[seat.category.label] === 0) {
        delete tickets[seat.category.label];
      }
    }

    // Remove the deselected seat from the correct category in selectedSeats
    selectedSeats[seat.category.label] = selectedSeats[seat.category.label].filter(selectedSeat => selectedSeat !== seat.seatId);

    // If no seats remain in this category, remove the category from selectedSeats
    if (selectedSeats[seat.category.label].length === 0) {
      delete selectedSeats[seat.category.label];
    }

    this.setState({
      tickets,
      selectedSeats // Update selectedSeats after seat deselection
    });;
  }

  render() {
    const { workspaceKey, seatsioEventId, loading, error, selectedSeats, ticket_settings } = this.state;
    
    const seatingChartPricing = ticket_settings.map(setting => ({
      category: setting.type,
      price: setting.amount ? setting.amount/100 : 0
    }));


    // Calculate total price for each category
    const seatCategories = Object.keys(selectedSeats).map(category => {
      const seatCount = selectedSeats[category].length;
      const seatPriceCents = ticket_settings.find(setting => setting.type === category)?.amount || 0;
      const seatPriceDollars = seatPriceCents / 100;  // Convert price from cents to dollars
      const totalPrice = seatPriceDollars * seatCount;
      return {
        category,
        seats: selectedSeats[category],
        seatCount,
        totalPrice
      };
    });

    const subtotal = seatCategories.reduce((sum, category) => sum + category.totalPrice, 0);


    if (loading) {
      return <div className="loading">Loading seating chart...</div>;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    return (
      <div className="SeatedEventReservation-form">
        <h3 className="SeatedEventReservation-heading">Reservation Details</h3>
        <div className="seating-chart-container">
          <SeatsioSeatingChart
            workspaceKey={workspaceKey}
            event={seatsioEventId}
            region="na"
            onObjectSelected={this.onSeatSelected}
            onObjectDeselected={this.onSeatDeselected}
            showFullScreenButton={false}
            pricing={seatingChartPricing}
            showLegend={true}  // Show/hide legend if needed
            priceFormatter= {function(price) {
                return '$' + price;
            }}
            chartRendered={chart => {
              chart.zoomTo(2);  // Zoom to 2x level after the chart is rendered
            }}
          />
        </div>
        {(
          <div className="EventReservation-selected-seats-summary">
            <h4 className="EventReservation-selected-seats-summary-title">Selected Seats</h4>

            {seatCategories.length > 0 ? (
              <ul className="seat-list">
                {seatCategories.map((seat, index) => (
                  <li key={index} className="seat-item">
                    <span className="seat-number">{seat.category} : {seat.seats.join(', ')}</span>
                    {seat.totalPrice > 0 && <span className="seat-price">Price: ${seat.totalPrice.toFixed(2)}</span>}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="EventReservation-selected-seats-summary-no-seats-selected">No seats selected.</p>
            )}

            <div className="EventReservation-selected-seats-summary-total-summary">
              <span className="total-label">Total:</span>
              <span className="total-amount">
              ${subtotal.toFixed(2)}
              </span>
            </div>
          </div>
        )}
        <div className="EventReservation-action">
          <SmartButtonStub
            className='mb-3'
            text="Confirm Reservation" 
            click={this.confirmSeats} 
            disabled={selectedSeats.length === 0} 
          />
          <SmartButtonStub
            className='mb-3'
            text="Back" 
            click={this.cancelReservation} 
          />
        </div>
      </div>
    );
  }
}

export default withRouter(SeatedEventReservationBlock);
