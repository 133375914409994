import { withErrorBoundary, Spinner, useEffect, SwitchField } from 'shared'
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import { useState } from 'react'

export default withErrorBoundary (props) ->
  [user, setUser] = useState null
  [saving, setSaving] = useState false

  inputChanged = (e) ->
    setUser { ...user, "#{e.target.name}": e.target.checked }

  submit = ->
    setSaving true
    await Backend.put "/profile", user: _.pick(user, 'is_searchable', 'shows_email', 'shows_phone', 'shows_address', 'shows_bio')
    setSaving false

  useEffect [], ->
    response = await Backend.get('/users/me')
    setUser response.user

  return <Spinner /> unless user

  <div className="PrivacyPage row justify-content-md-center">
    <form className="">
      <h3 className="text-center mb-3">Privacy Settings</h3>
      <SwitchField title="Visible and Searchable Profile" id="privacy-profile" name="is_searchable" model={user} onChange={inputChanged} />
      <SwitchField title="Phone Number Visible" id="privacy-phone" name="shows_phone"               model={user} onChange={inputChanged} />
      <SwitchField title="Email Address Visible" id="privacy-email" name="shows_email"              model={user} onChange={inputChanged} />
      <SwitchField title="Street Address Visible" id="privacy-address" name="shows_address"         model={user} onChange={inputChanged} />
      <SwitchField title="Bio Visible" id="privacy-bio" name="shows_bio"                            model={user} onChange={inputChanged} />
      <div className="mt-3">
        <button type="button" className="btn btn-primary mr-2" style={{minWidth: '100px'}} onClick={submit} disabled={saving}>
          {if saving then "Saving..." else "Save" }
        </button>
        <Link to="/settings" className="btn btn-secondary" style={{minWidth: '100px'}}>Cancel</Link>
      </div>
    </form>
  </div>
