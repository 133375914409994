import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const GoogleMapInner = withScriptjs(withGoogleMap(_props => {
  let props = { ..._props }
  if (props.defaultCenter == null) {
    props.defaultCenter = { lat: 37.8, lng: -122.4 }
    props.defaultZoom = 4
  }
  return (
    <GoogleMap
      defaultOptions={{ disableDefaultUI: true }}
      { ...props }
    >
      { props.children }
    </GoogleMap>
  )
}))

const CustomGoogleMap = props =>
  <GoogleMapInner
    googleMapURL={`//maps.googleapis.com/maps/api/js?v=3&key=${App.googleApiKey}`}
    loadingElement={<div className='gmap-loading' />}
    containerElement={<div className='gmap-container' />}
    mapElement={<div className='gmap-element' />}
    { ...props }
  />

CustomGoogleMap.Marker = Marker

export default CustomGoogleMap

// {/* <GoogleMap defaultZoom={11} defaultCenter={event.coords}>
//   { event.coords && <GoogleMap.Marker position={event.coords} /> }
// </GoogleMap> */}
