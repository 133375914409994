import { ParkApi } from 'api'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import { withErrorBoundary, connected, SearchBar } from 'shared'

class Parks extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.query = ParkApi.paramsFor('index').q
  }

  render() {
    let { id, eventId } = this.props.match.params
    let validId = !isNaN(id)
    let models = validId ? ParkApi.kick('list', { smart_button_id: id }) : ParkApi.kick('index', { q: this.query })
    
    let base = validId ? `/parks/${id}` : '/parks'
    let modelId = eventId || id
    return (
      <TopLevelPage className="Parks" modelId={modelId} base={base}>
        { !validId &&
          <div className="row">
            <div className="col-sm-6 col-lg-4 col-xl-3">
              <SearchBar onChange={this.queryDidChange} onSubmit={this.queryWouldSubmit} initialQuery={this.query} />
            </div>
          </div>
        }
        <ModelGrid models={models} base={base} />
        {App.showsButtonsOnEachFeed && <SmartButtonFeedList base={base} />}        
      </TopLevelPage>
    )
  }

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
}

export default withErrorBoundary(connected(Parks))
