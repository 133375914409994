import { MissingImage } from 'customizations'
import { Modal, withErrorBoundary, Formatter, connected } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import { Route, Switch, Redirect } from 'react-router-dom'
import { pluralize } from 'shared/util'
import SmartButtonApi  from 'api/smart-button-api'

class PersonModal extends Component {
  render() {
    let { person, base, smartButtons, reloadSmartButtons } = this.props

    return (
      <div className="PersonModal custom-modal">
        <div className="modal-head">
          <img className="modal-image" src={person.picture || MissingImage} />
          <img className="modal-image-preview" src={person.picture || MissingImage} />
          <div className="modal-titlebar">
            <h3 className="modal-title">{person.name}</h3>
            <div className="modal-titlebar-actions"></div>
          </div>
          <Modal.CloseButton />
        </div>
        <div className="modal-body d-block">
          <div>
            <div>
              {person.phone && <p>Phone: {person.phone}</p>}
              {person.email && <p>Email: {person.email}</p>}
              {person.address && <p>Address: {person.address}</p>}
              <p>{pluralize(person.followers, "follower", "followers")}</p>

              <h4>Upcoming Events Attending:</h4>
              {person.event_details && person.event_details.length > 0 ? (
                <div>
                  <ul>
                    {person.event_details.map((event, index) => (
                      <li key={index}>{event}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No upcoming events.</p>
              )}

              <h4>Clubs and Groups:</h4>
              {person.clubs_details && person.clubs_details.length > 0 ? (
                <div>
                  <ul>
                    {person.clubs_details.map((club, index) => (
                      <li key={index}>{club}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No clubs joined or interested.</p>
              )}
              {person.bio && <p className="person-bio-block" dangerouslySetInnerHTML={{__html: Formatter.simpleFormat(person.bio)}}></p>}
            </div>
            <div className="entity-smart-buttons smart-buttons text-center">
              {smartButtons && smartButtons.map(smartButton =>
                <SmartButton {...{smartButton, reloadSmartButtons, base}} key={smartButton.key} returnPath={application.path} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PersonModal.requestSmartButtons = (success) => {
  return SmartButtonApi.forUser(success)
}

export default SmartButton.containerComponent(null, withErrorBoundary(connected(PersonModal)), { Source: PersonModal })
