export function areEqualOrBlank(object1, object2) {
  if (object1 == object2) return true
  if (isBlank(object1) && isBlank(object2)) return true
  if (_.isEqual(object1, object2)) return true
  return false
}

export function isBlank(object) {
  return object == null ||
    object == {} ||
    typeof(object) == 'string' && object.trim() == ''
}

export function presence(value) {
  return isBlank(value) ? null : value
}

export function splitInTwo(string, separator) {
  let parts = string.split(separator)
  let first = parts.slice(0, parts.length - 1).join(separator)
  return [first, parts[parts.length - 1]]
}

export function momentMatchWeekday(date, weekdayName) {
  return date.toDate().getDay() == WeekDays.indexOf(weekdayName)
}

export const WeekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export function absolutizePath(path) {
  return `/${path}`.replace(/\/+/, '/')
}

export function pluralize(number, singular, plural) {
  return number == 1 ? `${number} ${singular}` : `${number} ${plural}`
}

export function randomInt(max = 1000000) {
  return Math.floor(Math.random() * max)
}
