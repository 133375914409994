import { SpinnerPrimary, SpinnerTertiary } from 'customizations'
import FAIcon from './fa-icon'

const colorMapping = { primary: SpinnerPrimary, tertiary: SpinnerTertiary }

export default function Spinner({ color = 'primary', text = null }) {
  let image = colorMapping[color]
  return (
    <div className={classNames("list-spinner", color)}>
      <img src={image} />
      {
        text && <span className='list-spinner-text'>{text}</span>
      }
      {/* <FAIcon icon="faSpinner" spin size="3x" /> */}
    </div>
  )
}
