import { SmartButtonApi } from 'api'
import { ChatController, OverlayController } from '.'

export default class SmartButtonController
  @runServerAction = (smartButton) ->
    ChatController.presentAdminSpinner(smartButton) if smartButton.shouldOpenNewChat

    response = await SmartButtonApi.runServerAction(smartButton)
    if response.shouldOpenNewChat
      ChatController.presentSmartResponse(response)
    else if response.shouldOpenOverlay
      OverlayController.show(response.html_content, title: response.displayName)

    return response
