import { withErrorBoundary, Spinner, NoResults } from 'shared'
import { Backend } from 'base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileWord, faFilePdf, faFileImage, faFile, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { InfoNavbar } from 'layout'

class Documents extends Component {
  state = { data: null }

  async componentDidMount() {
    let data = App.state.getObject('documents')
    if (!data) {
      data = await Backend.get('/documents')
      App.state.setObject({ documents: data })
    }
    this.setState({ data })
  }

  render() {
    let { data } = this.state
    return (
        <div className="documents-page">
          <InfoNavbar />
          {data == null ?
            <Spinner />
            :
            <React.Fragment>
              <div className="documents-page-preambule" dangerouslySetInnerHTML={{__html: data.header_html}}></div>
              <div className="documents-page-body">
                {data.groups.map(group =>
                  <div className="document-group" key={group.id}>
                    <h3>{group.name}</h3>
                    <ul className="document-links">
                      {group.documents.map(document =>
                        <li key={document.id}>
                          <a href={document.url} target="_blank">
                            {this.renderFileTypeIcon(document.type)}{' '}
                            {document.name}
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </React.Fragment>
          }
        </div>
    )
  }

  renderFileTypeIcon(fileType) {
    let icon = { image: faFileImage, word: faFileWord, pdf: faFilePdf, file: faFile, text: faFileAlt }[fileType]
    return <FontAwesomeIcon icon={icon} />
  }
}

export default withErrorBoundary(Documents)
