import { withErrorBoundary, Formatter } from 'shared'
import { absolutizePath } from 'shared/util'
import { MissingImage } from 'customizations'

function PeopleCard({ user, base = 'users' }) {
  return (
    <custom-card class="PeopleCard">
      <Link to={`${absolutizePath(base)}/${user.targetUid}`} className='plain-link'>
        <div className="card" style={{ alignItems: 'center' }}>
          <div className="card-img-container">
            <img className="card-img-rounded"
              src={user.picture || MissingImage}
            />
          </div>
          <div className="card-meta">
            <h5 className="card-heading">{user.first_name} {user.last_name}</h5>
          </div>
        </div>
      </Link>
    </custom-card>
  )
}

export default withErrorBoundary(PeopleCard, { className: 'custom-card' })
