import classNames         from 'classnames'
import { Modal }          from 'shared'
import { absolutizePath } from 'shared/util'
import EventModal                from './EventModal'
import EventCardBadge            from './EventCardBadge'
import { eventHours, eventDate } from './EventHelper'
import { MissingImage } from 'customizations'

 export default function EventCardMini({ event, base }) {
  return (
    <custom-card class="EventCardMini mini">
      <Link to={`${absolutizePath(base)}/${event.uid}`} className='plain-link'>
        <div className="card">
          <div className="card-upper">
            <i className={classNames("card-upper-icon", event.isReserved ? 'bg-primary' : '')}></i>
            <time className="card-upper-text" dateTime={event.date}>{eventDate(event, 'long')}</time>
          </div>
          <div className="card-img-container">
            <img className="card-img-top" src={event.image_preview_url || MissingImage} alt={event.name} />
            <EventCardBadge event={event} />
          </div>
          <div className="card-meta">
            <time className="card-superheading" dateTime={event.open_datetime}>{eventHours(event)}</time>
            <h5 className="card-heading">{event.name}</h5>
          </div>
        </div>
      </Link>
    </custom-card>
  )
}
