import { withErrorBoundary } from './error-boundary'
import { connected } from 'base/store-connector'
import { OverlayController } from 'controllers'

class Overlay extends Component
  render: ->
    <>
      <div className="overlay">
        <div className="overlay-dialog">
          <div className="overlay-content">
            <header className="overlay-header">
              <h2 className="overlay-title">{@props.title}</h2>
              <button type="button" className="overlay-close-button" onClick={@close}>
                <span>&times;</span>
              </button>
            </header>
            <article className="overlay-body" dangerouslySetInnerHTML={__html: @props.content}>
            </article>
            <footer className="overlay-footer">
            </footer>
          </div>
        </div>
      </div>
      <div className="overlay-backdrop" onClick={@close}></div>
    </>

  close: ->
    OverlayController.hide()

  @propTypes =
    title: PropTypes.string,
    content: PropTypes.string

  @defaultProps =
    title: 'An Overlay',
    content: "Nothing Here"

class OverlayContainer extends Component
  render: ->
    descriptor = application.state.overlay
    <div className="overlay-container">
      {
        <Overlay {...descriptor} /> if descriptor
      }
    </div>

export default withErrorBoundary connected OverlayContainer
