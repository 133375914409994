const API_ROOT = "/api/v1"

window.requireCustomAsset = (fileName, type) => {
  return require(`customizations/${window.FRONTEND}/${fileName}.png`)
}

window.requireCustomOrDefaultAsset = (fileName, type) => {
  try {
    if (type == 'png'){
      return require(`customizations/${window.FRONTEND}/${fileName}.png`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName}.gif`)
    }
  } catch {
    if (type == 'png'){
      return require(`customizations/default/${fileName}.png`)
    }
    else{
      return require(`customizations/default/${fileName}.gif`)

    }
  }
}

window.requireCustomAssetWithFallback = (fileName, fileName2, typeofFile, typeofFile2) => {
  try {
    if (typeofFile == 'jpg'){
      return require(`customizations/${window.FRONTEND}/${fileName}.jpg`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName}.gif`)

    }
    // return require(`customizations/${window.FRONTEND}/${fileName}`)
  } catch {
    if (typeofFile2 == 'jpg'){
      return require(`customizations/${window.FRONTEND}/${fileName2}.jpg`)
    }
    else{
      return require(`customizations/${window.FRONTEND}/${fileName2}.gif`)

    }
    // return require(`customizations/${window.FRONTEND}/${fileName2}`)
  }
}

window.$ = window.jQuery = require("jquery")
window.Backbone = require('backbone')
window.moment = require("moment")
window.classNames = require('classnames')
window._ = require("lodash")
window.React = require('react')
window.ReactDOM = require('react-dom')
window.PropTypes = window.PT = require('prop-types')
window.Link = require('react-router-dom').Link
window.NavLink = require('react-router-dom').NavLink
window.Redirect = require('react-router-dom').Redirect
window.Component = React.Component

const smoothscroll = require('smoothscroll-polyfill')
smoothscroll.polyfill()

require('bootstrap')
require('shared/extensions')

window.App = window.application = require('application').default

let Layout = require('layout/layout').default
document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Layout />, document.getElementById('react'))
})


// debug
window.Backend = require('base/backend').default
window.User = require('api/user').default
window.ParkApi = require('api/park-api').default
window.EventApi = require('api/event-api').default

window.addEventListener("message", function (event) {
  if (window.location.href.match('park')) {
    let params =  window.location.href.split('park:')[1].split('/')
    let park_id = params[0]
    let price = params[1]
    let date = params[2]
    let time = params[3]
    let service_id = null
    let tickets = ''

    if (window.location.href.includes('mobile=true')) { 
      service_id = params[4].split('?')[0]
      tickets = params[5].split('?')[0]
    } else {
      service_id = params[4];
      tickets = params[5];
    }

    let path = `/places/${park_id}/reservations/${date}/${time}?${window.location.href.split('?').pop()}`
    let url = path.startsWith(API_ROOT) ? path : `${API_ROOT}${path}`
    let method = "POST"
    let data = Object.assign(event.data, {'service_id': service_id, 'fran_amount': price, options: tickets});
    if (event.data.name == "franpos.payment.creditcard.completed") {
      return $.ajax({ url, method, data,
        beforeSend: xhr => { if (App.authToken) xhr.setRequestHeader("Authorization", `Token token=${App.authToken}`) },
        error: xhr => { 
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/failed'
            window.location = path
          } else {
            window.location = '/app' 
          }

        },
        success: xhr => {
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/success'
            window.location = path
          } else {

            let path = `/app#${window.fran_success_location}`
            window.location = path
          }
        }
      })
    }
  } else if (window.location.href.match('event')) {
    let event_id = ''
    let fran_amount = null
    if (window.location.href.includes('mobile=true')) { 
      event_id = window.location.href.split('event:').pop().split('/')[0]
      fran_amount = window.location.href.split('event:').pop().split('/')[1].split('?')[0]
      tickets = window.location.href.split('event:').pop().split('/')[2].split('?')[0]
    } else {
      event_id = window.location.href.split('event:').pop().split('/')[0]
      fran_amount = window.location.href.split('event:').pop().split('/')[1]
      tickets = window.location.href.split('event:').pop().split('/')[2]
    }
    let path = `/events/${event_id}/reservation?${window.location.href.split('?').pop()}`
    let url = path.startsWith(API_ROOT) ? path : `${API_ROOT}${path}`
    let method = "POST"
    
    let data = Object.assign(event.data, {'fran_amount': fran_amount, spots: tickets});
    if (event.data.name == "franpos.payment.creditcard.completed") {
      return $.ajax({ url, method, data,
        beforeSend: xhr => { if (App.authToken) xhr.setRequestHeader("Authorization", `Token token=${App.authToken}`) },
        error: xhr => { 
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/failed'
            window.location = path
          } else {
            window.location = '/app' 
          }
        },
        success: xhr => {
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/success'
            window.location = path
          } else {

            let path = `/app#${window.fran_success_location}`
            window.location = path
          }
        }
      })
    }
  } else {
    if (event.data.name == "franpos.payment.creditcard.completed") {
      let path = '/payments'
      let url = path.startsWith(API_ROOT) ? path : `${API_ROOT}${path}`
      let method = "POST"

      let data = {}
      if (window.location.href.includes('mobile=true')) {
        let auth = window.location.href.split('?')[1].split('&')
        data = {'smart_button_id': window.location.href.split('sb:')[1].split('/')[0], 'token': auth[0].split('token=')[1], 'mobile': true}
      } else {
        data = {'smart_button_id': window.location.href.split('sb:')[1].split('/')[0]}
      }

      data = Object.assign(event.data, data);
      return $.ajax({ url, method, data,
        beforeSend: xhr => { if (App.authToken) xhr.setRequestHeader("Authorization", `Token token=${App.authToken}`) },
        error: xhr => { 
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/failed'
            window.location = path
          } else {
            window.location = '/app' 
          }
        },
        success: xhr => {
          if (window.location.href.includes('mobile=true')) {
            let path = '/app#/payment/success'
            window.location = path
          } else {

            let path = `/app`
            window.location = path
          }
        }
      })

    }
  }
});
