import { Formatter } from 'shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'

export default function CommunityDatePicker({startDate, endDate, onChange}) {
  let updateDates = date => {
    date = moment(date)
    startDate = startDate && moment(startDate)
    endDate = endDate && moment(endDate)

    let diffToStart = date.diff(startDate, 'days')
    let diffToEnd = date.diff(endDate, 'days')
    let updates = {}

    if (startDate && endDate == null && diffToStart > 0)
      updates = {end: date}
    else if (startDate && endDate == null)
      updates = {start: date, end: startDate}
    else if (endDate && startDate == null)
      if (diffToEnd > 0)
        updates = {start: endDate, end: date}
      else
        updates = {start: date}
    else if (Number.isNaN(diffToStart))
      updates = {start: date}
    else if (Number.isNaN(diffToEnd) && diffToStart < 0)
      updates = {start: date, end: startDate}
    else if (Number.isNaN(diffToEnd))
      updates = {end: date}
    else if (diffToStart == 0 || diffToEnd == 0)
      updates = {start: date, end: null}
    else if (diffToStart < 0)
      updates = {start: date}
    else if (diffToEnd > 0)
      updates = {end: date}
    else if (Math.abs(diffToStart) < Math.abs(diffToEnd))
      updates = {start: date}
    else
      updates = {end: date}

    if (updates.start !== undefined) onChange({startDate: updates.start})
    if (updates.end !== undefined) onChange({endDate: updates.end})
  }

  let resetDates = () => {
    onChange({endDate: null, startDate: null})
  }

  let renderDatesInfo = () => {
    if (startDate && endDate && startDate == endDate) return `${Formatter.dateUS(startDate)}`
    if (startDate && endDate) return `${Formatter.dateUS(startDate)}—${Formatter.dateUS(endDate)}`
    if (startDate && endDate == null) return `${Formatter.dateUS(startDate)}`
    if (startDate == null && endDate) return `Before ${Formatter.dateUS(endDate)}`
    if (startDate == null && endDate == null) return `Showing all dates`
  }

  startDate = startDate && moment(startDate).toDate()
  endDate = endDate && moment(endDate).toDate()

  return (
    <div className="custom-calendar">
      <h5 className='custom-calendar-heading'>Search by Date</h5>

      <DatePicker
        inline
        selected={startDate && endDate ? null : startDate || endDate}
        startDate={startDate}
        endDate={endDate}
        onChange={updateDates}
        isClearable
        // formatWeekDay={(l, d) => {return 'T'}}
      />

      <p className="text-muted text-sm">
        {renderDatesInfo()}
        &nbsp;
        {(startDate || endDate) &&
          <i className="icon-btn" onClick={resetDates} title="Reset dates">
            <FontAwesomeIcon icon={faTimesCircle} />
          </i>
        }
      </p>
    </div>
  )
}
