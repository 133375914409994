import { ApiModel } from 'base'
import ParkReservation from './park-reservation'

export default class ParkReservationTimeSlot extends ApiModel {
  constructor(attributes) {
    super(attributes)
    if (this.reservation)
      this.reservation = new ParkReservation(this.reservation)
  }

  get available() { return this.state == 'available' }

  get militaryTime() { return this.time.replace(':', '') }
}
