import classNames from 'classnames'

export default class ErrorBoundary extends Component
  @propTypes =
    className: PropTypes.string

  constructor: (props) ->
    super props
    @state = error: null, errorInfo: null

  componentDidCatch: (error, errorInfo) ->
    @setState error: error, errorInfo: errorInfo

  render: ->
    if @state.errorInfo
      <div className={classNames 'error-boundary', @props.className}>
        <details style={ whiteSpace: 'pre-wrap' }>
          <summary>Component failed</summary>
          {@state.error and @state.error.toString()}
          {@state.errorInfo.componentStack.substring(0, 150)}
        </details>
      </div>
    else
      @props.children

export withErrorBoundary = (InnerComponent, boundaryProps = {}) ->
  WithErrorBoundary = (props) ->
    <ErrorBoundary {...boundaryProps}>
      <InnerComponent {...props} />
    </ErrorBoundary>


#     { div, p, summary, details } = Rendering
#     if @state.errorInfo
#       div className: classNames('error-boundary', @props.className),
#         p {}, "Hello"
#         details style: { whiteSpace: 'pre-wrap' },
#           summary {}, "Component failed"
#           "#{@state.error and @state.error.toString()}"
#           "#{@state.errorInfo.componentStack.substring(0, 150)}"
# Rendering =
#   div: -> React.createElement('div', ...arguments)
#   p: -> React.createElement('p', ...arguments)
#   summary: -> React.createElement('summary', ...arguments)
#   details: -> React.createElement('details', ...arguments)
