import { ApiModel } from 'base'
import { Formatter } from 'shared'

export default class EventOccurrence extends ApiModel {
  constructor(event, attrs) {
    super(attrs)
    this.event = event
  }

  get dateText() { return Formatter.dateUS(this.date) }
  get timeText() { return _.compact([Formatter.hhmmToAmpm(this.time), Formatter.hhmmToAmpm(this.close_time)]).join('–') }
  get displayName() { return `${this.dateText} ${this.timeText}` }
  get canBeReserved() { return this.is_reservation && this.event.isEnrolled && !this.relationshipInfo.is_reserved }
  get relationshipInfo() { return this.relationship || { } }
}
