import ElipsisImage from 'images/ellipsis-50px'
import { Formatter } from 'shared'
import { withRouter } from 'react-router'

class OccurrenceRow extends Component {
  state = { }

  render() {
    let { event, occurrence, relationship = occurrence.relationship || {}, base } = this.props
    let { performing } = this.state
    console.log(event)
    console.log(relationship)

    // <td className="occurrence-row-price">{event.is_payment && Formatter.amountFromCents(event.amount)}</td>
    return [
      <tr className={"occurrence-row"} key="main">
        <td className="occurrence-row-date">{occurrence.dateText}</td>
        <td className="occurrence-row-time">{occurrence.timeText}</td>
        {event.isRecurring &&
          <>
            <td className="occurrence-row-spots">
              {relationship.is_reserved &&
                <span className='badge' title={`${relationship.spots} spots reserved`}>{relationship.spots}</span>
              }
            </td>
            <td className="occurrence-row-actions">
              <div className="occurrence-row-button">
                { console.log(event) }
                {
                  event.is_enrollment_required && !event.isEnrolled && event.isReservation ?
                    <OccurrenceRowButton text="Enrollment Required" disabled /> :
                      event.isEnrolled && !relationship.is_reserved && !relationship.is_waitlisted ?
                        <OccurrenceReservationLink occurrence={occurrence} base={base} event={event} /> :
                          (relationship.is_reserved && relationship.can_be_cancelled && event.is_cancelable) ?
                            <OccurrenceRowButton text="Cancel Reservation" onClick={() => this.runOccurreneAction(occurrence, 'cancelOccurrence')} spinning={performing} /> :
                              (relationship.is_waitlisted && event.is_cancelable) ?
                                <OccurrenceRowButton text="Cancel Waitlisted Reservation" onClick={() => this.runOccurreneAction(occurrence, 'cancelOccurrence')} spinning={performing} /> :
                                  (relationship.is_reserved && relationship.can_be_cancelled && !event.is_cancelable) || (relationship.is_waitlisted && !event.is_cancelable) ?
                                    (relationship.is_waitlisted ? "In Waiting List" : "Reserved") : 
                                      event.isReservation?
                                        <OccurrenceReservationLink occurrence={occurrence} base={base} event={event} /> :
                                          !event.isInterested ?
                                            relationship.subscription_status == 'interested' ?
                                              <OccurrenceRowButton text="Remove Reminder" onClick={() => this.runOccurreneAction(occurrence, 'cancelInterestInOccurrence')} spinning={performing} /> :
                                              <OccurrenceRowButton text="Set Reminder" onClick={() => this.runOccurreneAction(occurrence, 'interestInOccurrence')} spinning={performing} /> :
                                            null
                }
              </div>
            </td>
          </>
        }
      </tr>,
      occurrence.note &&
        <tr className={classNames("occurrence-note-row", {cancelled: occurrence.cancelled})} key="note">
          <td className="occurrence-row-note" colSpan={3}>
            {occurrence.note}
          </td>
        </tr>
    ]
  }

  async runOccurreneAction(occurrence, action) {
    let { event, base, history } = this.props

    if (User.current && User.current.guest) {
      App.state.setObjectSilent({ afterSignupPath: App.path })
      App.go(`${this.props.base}/guest-signup`)
      return
    }

    delete App.state.objects.interruptedReservation
    if (action == 'reserveOccurrence' && event.is_payment && !App.user.hasCard) {
      App.state.objects.interruptedReservation = {
        eventId: event.id, occurrenceId: event.occurrence_id, path: `${base}/${event.uid}`
      }
      history.push("/settings/payment")
      return
    }

    this.setState({ performing: true })
    await event[action](occurrence)
    this.setState({ performing: null })
  }
}

export default withRouter(OccurrenceRow)

const OccurrenceRowButton = ({text, disabled, spinning, onClick}) =>
  <button className={`btn btn-sm btn-outline-primary occurrence-row-btn ${spinning ? 'spinning' : ''}`}
    disabled={disabled || spinning}
    onClick={spinning ? null : onClick}
  >
    {spinning && <img src={ElipsisImage} className="occurrence-row-btn-spinner" />}
    <span>{text}</span>
  </button>

const OccurrenceReservationLink = ({ occurrence, base, event }) =>
  <span>
    { !event.waitlistedItemsId.includes(occurrence.id) &&
      <Link className={`btn btn-sm btn-outline-primary occurrence-row-btn`}
        to={`${base}/${occurrence.event.uid}::reserve:${occurrence.id}`}
      >
        <span>Reserve</span>
      </Link>
    }
  </span>
