import { GradientedScroller, connected, withErrorBoundary, NoResults} from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import SlideEventCard from 'ui/events/SlideEventCard'
import PeopleCard from 'ui/people/PeopleCard'
import { DateRangePicker } from 'react-date-range';
import { addDays, format} from 'date-fns';
import { useEffect, useState } from 'react';

intervalId = null

GuestSecretCalenderView = ({match}) ->
  feedItemId = match.params.id
  [slides, setSlides] = useState([])
  [sliderTime, setSliderTime] = useState("5000")
  [refreshTime, setRefreshTime] = useState(2000)
  [initialized, setInitialized] = useState(false)
  [userCounter, setUserCounter] = useState()
  [eventCounter, setEventCounter] = useState()

  loadSlideData = () =>
    Backend.get('/slides', {user_set_counter: userCounter, event_counter: eventCounter}).then (data) =>
      setSlides(data.slides)
      setSliderTime(data.slider_time)
      setRefreshTime(if data.refresh_time then data.refresh_time else 3600000)
      setUserCounter(if data.user_set_counter then data.user_set_counter else 0)
      setEventCounter(data.event_counter)

  useEffect(() =>
    console.log("Inside useEffect")
    unless initialized
      loadSlideData().then =>
        setInitialized true

    clearInterval(intervalId)  if intervalId
    intervalId = setInterval(() =>
      loadSlideData()
    , refreshTime )
    return # Don't remove this line
  , [initialized, refreshTime, userCounter, eventCounter])

  <TopLevelPage className="Calendar is-diabled" modelId={feedItemId} base="/community-calendar">
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {if slides?
          slides.map((slide, index) => (
            <div key={index} className={if index == 0 then "carousel-item active" else "carousel-item"} data-interval={slide.timer*1000}>
              {if slide.type is 'image'
                <div className="iframe-modal">
                  <img src={slide.data} style={{ width: '100%', height: '500px' }}/>
                </div>
              else if slide.type is 'web'
                <div className="iframe-modal">
                  <iframe src={slide.file_path} width="100%" height="500px" scrolling="no" style={{  border: 'none', overflow: 'hidden' }} />
                </div>
              else if slide.type is 'checkin'
                <div>
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <h1 className="section-heading-lowercase my-3">{slide.data.title}</h1>
                    </div>
                  </div>
                  <GradientedScroller containerClassName="feed-columns row">
                    {
                      slide.users.map((model) => (
                        <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                          <div className="feed-column-body">
                            <PeopleCard user={model} key={model.id} base='/large-screen' />
                          </div>
                        </section>
                      ))
                    }
                  </GradientedScroller>
                </div>
              else if slide.type is 'event'
                <div>
                  {if slide.data.title?
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <h1 className="section-heading-lowercase my-3">{slide.data.title}</h1>
                      </div>
                    </div>
                  else
                    <div className="centered-title"></div>
                  }
                  {if (slide.data.events.length == 0)
                    <NoResults>We’re sorry, this section contains no events.</NoResults>
                  else
                    <GradientedScroller containerClassName="feed-columns row">
                    {
                      slide.data.events.map((model) => (
                        <section className="feed-column col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
                          <div className="feed-column-body">
                            <SlideEventCard event={model} key={model.id} base='/large-screen' />
                          </div>
                        </section>
                      ))
                    }
                  </GradientedScroller>
                  }
                </div>
              else
                <div key="noSlides">No slides available</div>
              }
            </div>
          ))
        else
          <div key="noSlides">No slides available</div>
        }
      </div>
    </div>

  </TopLevelPage>

export default withErrorBoundary connected GuestSecretCalenderView
