import { withErrorBoundary } from 'shared'

class PaymentFailedPage extends Component {

  render() {
    return (
      <div className="row">
        <h1>Payment Failed</h1>
      </div>
        
    )
  }
}
export default withErrorBoundary(PaymentFailedPage)
