import { Formatter }  from 'shared'
import { ApiModel }   from 'base'
import UserRequestApi from './user-request-api'
import ChatMessage    from './chat-message'
import SmartButton    from './smart-button'

export default class UserRequest extends ApiModel {
  constructor(attributes) {
    super(attributes)
    this.messages = (this.messages || []).reverse()
    this.messages = this.messages.map(m => new ChatMessage({...m, parentUserRequestId: this.id}))
  }

  get displayName() { return this.user_button && this.user_button.title }
  get uid() { return `user-request:${this.id}` }
  get subtitle() { return this.created_at }
  get closed() { return this.state == 'processed' }
  get open() { return this.state == 'pending' }
  get userButton() { return new SmartButton(this.user_button) }

  addMessage(text) {
    this.messages.push(new ChatMessage({ text, sender: 'user', id: Date.now() }))
  }
}
