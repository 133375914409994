import { Formatter } from 'shared'
import { ApiModel } from 'base'
import SmartButton from './smart-button'
import UserRequestApi from './user-request-api'
import ChatMessage from './chat-message'

export default class SmartButtonUserRequest {
  constructor(smartButton) {
    this.smartButton = smartButton
  }

  get displayName() { return this.smartButton.title }
  get uid() { return `user-request:smart-button:${this.smartButton.id}` }

  get messages() {
    let childButtons = this.smartButton.children || []
    return childButtons.map(button => ({
      text: button.title,
      id: button.id,
      fromAdmin: true,
      smartButton: new SmartButton({ parent: this.smartButton, ...button })
    }))
  }
}
