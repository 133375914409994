import classNames from 'classnames'

function scopeMatched(trackedScopes, changedScopes) {
  if (changedScopes == null || trackedScopes == null || changedScopes.length == 0)
    return true
  for (let changedScope of _.castArray(changedScopes))
    for (let trackedScope of trackedScopes)
      if (changedScope && changedScope.startsWith(trackedScope))
        return true
  return false
}

export default class StoreConnector extends Component {
  static propTypes = {
    trackedScopes: PropTypes.array
  }

  componentDidMount()    { App.state.on( 'change', this.updated) }
  componentWillUnmount() { App.state.off('change', this.updated) }
  updated = changedScopes => {
    let { trackedScopes, Component } = this.props
    if (trackedScopes == null || scopeMatched(trackedScopes, changedScopes))
      this.forceUpdate()
    else
      console.info(`  %cskip StoreConnector<${Component.name}>`, 'color: green')
  }

  render() {
    let { Component, componentProps } = this.props
    // console.info(`%crender StoreConnector<${Component.name}>`, 'color: green')
    return <Component {...componentProps} store={App.state} />
  }
}

export function connected(InnerComponent, connectionProps = {}) {
  return props => <StoreConnector {...connectionProps} Component={InnerComponent} componentProps={props} />
}
