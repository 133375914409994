import { EventApi, MenuApi } from 'api'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import { withErrorBoundary, connected } from 'shared'

class MenuItems extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  render() {
    let { menuId, id, subId } = this.props.match.params
    let menu = application.state.getModel(`menu:${menuId}`)
    let models = Number(menuId) > 0 || menuId == 'saved_amenities' ?
      MenuApi.kick('show', { id: menuId }, {actionId: `${menuId}`}) :
      EventApi.kick(menuId)
    let base = `menu/${menuId}`
    let modalBase = subId ? base + `/${id}` : base
    let modelId = subId || id

    return (
      <TopLevelPage className="CustomMenu" modelId={modelId} base={modalBase} pageTitle={menu?.name}>
        <ModelGrid models={models} base={base} />
        <div className="row justify-content-center custom_menu-container">
          <div className="col-md-8">
            <h1 className="custom_menu-header">Quick Links and Community Resources</h1>
          </div>
        </div>
        {App.showsButtonsOnEachFeed && <SmartButtonFeedList base={'/' + base} />}
      </TopLevelPage>
    )
  }
}

export default withErrorBoundary(connected(MenuItems))
