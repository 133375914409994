import Api from 'base/api'
import Retail from './retail'

class RetailApi extends Api {
  collectionName = 'retails'

  async index(params) {
    let json = await Backend.get('/retails', params)
    return this.state.triggerNone(_ =>
      json.retails.map(r => new Club(r))
    )
  }

  async show(id) {
    return this.receiveModel(await Backend.get(`/retails/${id}`))
  }

  async enroll(retail) {
    return this.receiveModel(await Backend.post(`/retails/${retail.id}/membership`))
  }

  async leave(retail) {
    return this.receiveModel(await Backend.destroy(`/retails/${retail.id}/membership`))
  }

  receiveModel(json) {
    let model = new Retail(json.retail)
    this.state.updateModel(model)
    return model
  }
}

export default new RetailApi
