import { RetailApi } from 'api'
import { withErrorBoundary, connected, SearchBar } from 'shared'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'

class Retails extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.query = RetailApi.paramsFor('index').q
  }

  render() {
    let { id, eventId } = this.props.match.params
    let models = RetailApi.kick('index', { q: this.query })

    return (
      <TopLevelPage className="Parks" modelId={eventId || id} base={eventId ? `/retails/${id}` : '/retails'}>
        <div className="row">
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <SearchBar onChange={this.queryDidChange} onSubmit={this.queryWouldSubmit} initialQuery={this.query} />
          </div>
        </div>
        <ModelGrid models={models} base={'retails'} />
        {App.showsButtonsOnEachFeed && <SmartButtonFeedList base='/retails' />}
      </TopLevelPage>
    )
  }

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
}

export default withErrorBoundary(connected(Retails))
