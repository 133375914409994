import { FAIcon }            from 'shared'

export default class PaymentCardItem extends Component {
  render() {
    let { card } = this.props
    let cardIcon = CardBrandToIconMapping[card.brand.toLowerCase()] || 'faCreditCard'
    return (
      <div className="credit-card my-3">
        <div className={classNames("credit-card-info", {inactive: !card.is_primary})}>
          <FAIcon icon={cardIcon} className='credit-card-brand-icon' size='lg'/>{card.brand} ending in <b>{card.last_4}</b>
        </div>
        <div className="credit-card-expiration">
          exp {card.exp_month}/{card.exp_year}
        </div>
        <div className="credit-card-actions">
          {
            !card.is_primary && <button className="btn btn-sm smart-button" onClick={this.activateCard}>Make Primary</button>
          }
          <button className="btn btn-sm btn-danger smart-button" onClick={this.removeCard}>Remove</button>
        </div>
      </div>
    )
  }

  removeCard = async () => {
    let { card, cardsDidUpdate } = this.props
    let result = await Backend.destroy(`/profile/credit_cards/${card.id}`)
    cardsDidUpdate(result.cards)
  }

  activateCard = async () => {
    let { card, cardsDidUpdate } = this.props
    let result = await Backend.post(`/profile/credit_cards/${card.id}/activate`)
    cardsDidUpdate(result.cards)
  }
}

const CardBrandToIconMapping = {
  visa: 'faCcVisa',
  mastercard: 'faCcMastercard',
  discover: 'faCcDiscover',
  jcb: 'faCcJcb',
}
