import ElipsisImage from 'images/ellipsis-50px'

export default class SmartButtonStub extends Component {
  state = { }

  componentWillUnmount() { this.mounted = false }

  render() {
    let { className, disabled, click, children, text, inverted, buttonType, style } = this.props
    let spinning = this.props.spinning || this.state.spinning
    let classes = classNames('smart-button btn', className, { processing: spinning, inverted: inverted && !spinning })

    return (
      <button disabled={disabled} className={classes} onClick={() => this.onClick()} type={buttonType} style={style}>
        <span className="smart-button-text">{children || text}</span>
        {spinning && <img src={ElipsisImage} className="smart-button-spinner" />}
      </button>
    )
  }

  async onClick() {
    this.setState({ spinning: true })
    if (this.props.click)
      await this.props.click()
    if (this.mounted !== false)
      this.setState({ spinning: false })
  }
}
