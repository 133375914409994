import FAIcon from './fa-icon'

export default class GradientedScroller extends Component {
  static propTypes = {
    children: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = { scrolledToEnd: true, scrolledToStart: true }
    this.container = React.createRef()
  }

  componentDidMount()  {
    window.addEventListener("resize", this.updateScrolledToEnd)
    this.updateScrollState()
  }

  componentDidUpdate() {
    this.updateScrollState()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScrolledToEnd)
  }

  render() {
    let { children, containerClassName } = this.props
    let { scrolledToEnd, scrolledToStart } = this.state
    let classes = classNames('gradiented-scroller', {
      'scrolled-to-end': scrolledToEnd,
      'scrolled-to-start': scrolledToStart,
    })

    return (
      <div className={classes}>
        <button className="feed-scroll-btn btn left" onClick={() => this.scroll(-1)}>
          <FAIcon icon='faAngleLeft' />
        </button>
        <div className="feed-gradient left"></div>
        <div className={`gradiented-scroller-inner ${containerClassName}`} ref={this.container} onScroll={this.updateScrollState}>
          { children }
        </div>
        <button className="feed-scroll-btn btn right" onClick={() => this.scroll(1)}>
          <FAIcon icon='faAngleRight' />
        </button>
        <div className="feed-gradient right"></div>
      </div>
    )
  }

  scroll = reverse => {
    let container = this.container.current
    container.scrollBy({ left: reverse * container.clientWidth * .66, behavior: 'smooth' })
  }

  updateScrollState = () => {
    let container = this.container.current
    if (container) {
      let scrolledToEnd = container.scrollLeft + container.clientWidth + 50 > container.scrollWidth
      let scrolledToStart = container.scrollLeft == 0
      if (scrolledToEnd != this.state.scrolledToEnd || scrolledToStart != this.state.scrolledToStart)
        this.setState({ scrolledToStart, scrolledToEnd })
    }
  }
}
