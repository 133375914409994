import { withErrorBoundary, Spinner, NoResults, FAIcon } from 'shared'
import * as Util from 'shared/util'
import { TopLevelPage } from 'ui'

logPrefix = 'vc -- '
errHandler = (message) -> (err) -> warn(message, err)
okHandler = (message) -> () -> log(message)
log  = (message, args...) -> console.log  "%c#{logPrefix} #{message}", 'color: magenta', args...
warn = (message, args...) -> console.warn "%c#{logPrefix} #{message}", 'color: magenta', args...

# https://docs.agora.io/en/Voice/API%20Reference/web/globals.html
export default class VideoChat extends Component
  state: { supportStatus: null, browserSupported: true, ringing: false, talking: false }

  componentWillMount: ->
    if !AgoraRTC.checkSystemRequirements()
      @setState browserSupported: false

  componentDidMount: ->
    @checkStatus()
    @checkStatusInterval = setInterval @checkStatus, 5000

  componentWillUnmount: ->
    clearInterval @checkStatusInterval

  render: ->
    { ringing, talking, supportStatus, browserSupported } = @state

    if !browserSupported
      return <TopLevelPage className="VideoChat">
        <p className='alert alert-warning text-center'>Please use the mobile app to call support. Thank you.</p>
      </TopLevelPage>

    supportStatusText = { online: 'Support Online', offline: 'Support Offline', busy: 'Support Busy' }[supportStatus] ? 'Unavailable'
    supportStatusClass = { online: 'badge-success', offline: 'badge-danger', busy: 'badge-warning' }[supportStatus] ? ''

    <TopLevelPage className="VideoChat" base="/video-chat">
      <p className='support-status text-center'>
        <span className={classNames('badge badge-pill', supportStatusClass)}>{supportStatusText}</span>
        <span className='video-buttons px-2'>
          {
            if ringing || talking
              <span>
                {
                  if ringing
                    <span className='pr-2'>
                      <FAIcon icon='faSpinner' spin />
                    </span>
                }
                <button className="btn btn-sm btn-red btn-stop-call" onClick={@endCall}>
                  <FAIcon icon='faPhoneSlash' rotation={90} /> Hang Up
                </button>
              </span>
            else
              if supportStatus is 'online'
                <button className="btn btn-sm btn-green btn-start-call" onClick={@startCall}>
                  <FAIcon icon='faPhone' rotation={90} /> Call
                </button>
          }
        </span>
      </p>
      <div className="row">
        <div className="col-md-9 remote-video-col">
          <div id="agora-video"></div>
        </div>
        <div className="col-md-3 local-video-col">
          <div id="agora-local-stream"></div>
        </div>
      </div>
    </TopLevelPage>


  checkStatus: =>
    { status } = await Backend.get '/video_chat/status'
    @setState supportStatus: status

  startCall: =>
    clearInterval @ringInterval
    @setState ringing: true
    result = await Backend.post "/video_chat/calls"
    @channelId = result.channel_id
    @ringInterval = setInterval @ring, 2000

  ring: =>
    result = await Backend.put "/video_chat/calls/#{@channelId}/ring"
    if result.status is 'answering'
      clearInterval @ringInterval
      @setState ringing: false
      @answer()

  endCall: =>
    if @state.talking
      if @client
        if @localStream
          # @client.unpublish @localStream, errHandler("Stream unpublishing failed")
          @localStream.stop() if @localStream.isPlaying()
          @localStream.close()
          delete @localStream
        @client.leave okHandler("left channel"), errHandler("leave channel failed")
        delete @client
        $('#agora-video').html('')

    clearInterval @ringInterval
    await Backend.destroy "/video_chat/calls/#{@channelId}"
    delete @channelId
    @setState ringing: false, talking: false

  answer: ->
    log 'start video stream', @channelId
    @setState talking: true
    @join()

  join: ->
    @getDevices()
    @client = window.$client = AgoraRTC.createClient mode: 'live'
    @client.init App.data.settings.agoraAppId, =>
      @client.join null, @channelId, null, (uid) =>
        log "agora user joined channel", uid
        @localStream = window.$localStream = AgoraRTC.createStream streamID: uid, audio: true, video: true, screen: false
        @localStream.setVideoProfile '720p_3'
        @localStream.on "accessAllowed", okHandler("media access allowed")
        @localStream.on "accessDenied", errHandler("media access denied")
        @localStream.init =>
          log "getUserMedia success"
          @resizeLocalPlayer()
          @localStream.play 'agora-local-stream'
          @client.publish @localStream, errHandler("publish local stream error")
          @client.on 'stream-published', okHandler("publish local stream success")
        , errHandler("getUserMedia failed")
      , errHandler('agora channel join failed')
    , errHandler('agora init failed')

    @client.on 'error', (err) =>
      warn "got error msg", err.reason
      # if err.reason === 'DYNAMIC_KEY_TIMEOUT'
      #   console.warn('Should renew channel key!')
      #   client.renewChannelKey channelKey,
      #     okHandler("Renew channel key successfully")
      #     errHandler("Renew channel key failed")

    @client.on 'stream-added', (e) =>
      log "agora stream added", e.stream.getId()
      @client.subscribe e.stream, errHandler('subscribe stream failed')

    @client.on 'stream-subscribed', (e) =>
      { stream } = e
      streamId = stream.getId()
      streamDivId = "agora-remote-#{streamId}"
      log "Subscribe remote stream successfully", stream, streamId
      unless $("#agora-video ##{streamDivId}").length
        remoteWidth = $('.VideoChat .remote-video-col').width()
        $('#agora-video').append $('<div>').attr(id: streamDivId, class: 'agora-remote').
          css(width: remoteWidth, height: remoteWidth / 1.6)
      stream.play streamDivId #, muted: true
      stream.unmuteAudio()

    @client.on 'stream-removed', (e) =>
      { stream } = e
      stream.stop()
      $("agora-remote-#{stream.getId()}").remove()
      log "remote stream is removed " + stream.getId()
      @endCall()

    @client.on 'peer-leave', (e) =>
      { stream } = e
      if stream
        stream.stop()
        $("agora-remote-#{stream.getId()}").remove()
        log "peer-leaved from this channel", e
        @endCall()

  resizeLocalPlayer: ->
    localWidth = $('.VideoChat .local-video-col').width()
    $('#agora-local-stream').css(width: localWidth, height: localWidth / 1.6)

  getDevices: ->
    # AgoraRTC.getDevices (devices) =>
    #   for device in devices
    #     console.log device
