import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export default class ChatTopics extends Component {
  render() {
    let { userRequests, onSelect } = this.props
    let visibleRequests = userRequests && userRequests.filter(ur => !ur.hidden)
    return (
      <div className="chat-topics chat-body-scrollbox">
      {
        visibleRequests == null || visibleRequests.length == 0 ?
          <div className="chat-no-messages">You have no open requests at this time.</div> :
          visibleRequests.map(request =>
            <ChatTopic userRequest={request} key={request.id} onSelect={onSelect} />
          )
      }
      </div>
    )
  }
}

function ChatTopic({ userRequest, onSelect }) {
  let classes = classNames("chat-topic btn", { closed: userRequest.status == 'processed' })
  return (
    <a className={classes} href='#' onClick={e => { e.preventDefault(); onSelect(userRequest) }}>
      <span className="chat-topic-title">{userRequest.displayName}</span>
      <i className="chat-topic-chevron">
        <FontAwesomeIcon icon={faChevronRight} />
      </i>
    </a>
  )
}
