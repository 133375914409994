import { Api } from 'base'
import Menu from './menu'
import Model from './model'

class MenuApi extends Api {
  collectionName = 'menus'

  async load() {
    let mobile_param = location.hash.split('?').pop()
    let results = await Backend.get(`/menus?${mobile_param}`)
    let menus = results.menus.map(r => new Menu(r))
    // if (App.disableHomeFeed) {
    //   menus = menus.filter(menu => menu.id != 'my_feed')
    // }
    return menus
  }

  async show({ id }) {
    let result = await Backend.get(`/menus/${id}`)
    if (result.menu && result.menu.items)
      return _.compact(result.menu.items.map(r => new Model.fromJSON(r)))
    else
      return []
  }
}

export default new MenuApi
