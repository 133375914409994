export default function GoogleStaticMap({ defaultCenter, defaultZoom, address }) {
  let { lat, lng } = defaultCenter || { }
  if (lat == null && lng == null) return null

  let zoom = defaultZoom || 10
  let size = "300x200"
  let bigMapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`

  let url = "//maps.googleapis.com/maps/api/staticmap?" +
    `center=${lat},${lng}&` +
    `zoom=${zoom}&` +
    `size=${size}&` +
    "scale=2&" +
    "maptype=roadmap&" +
    `markers=color:red|${lat},${lng}&` +
    `key=${App.googleApiKey}`

  return (
    <a href={bigMapUrl} target="_blank">
      <img src={url} className='static-gmap' />
    </a>
  )
}
