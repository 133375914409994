import { withErrorBoundary} from 'shared'

class MessageDashboard extends Component {

  render() {
    return (
      <div className="People center-smart-buttons">
        <div className="left-container">
          <h4 className="left-heading my-4">Please select one from the options below</h4>
        </div>
        <div className="entity-smart-buttons smart-buttons my-4 center-buttons">
          <Link to={`/sms-opt-in`} className='btn btn-back btn-back-lg'>
            {`Opt-In`}
          </Link>
          <Link to={`/sms-opt-out`} className='btn btn-back btn-back-lg'>
            {`Opt-Out`}
          </Link>
        </div>
      </div>

    )
  }  
}

export default withErrorBoundary(MessageDashboard)