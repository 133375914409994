import ElipsisImage from 'images/ellipsis-50px'
import { Spinner, NoResults, FAIcon } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import { SmartButtonController } from 'controllers'
import { SmartButton as SmartButtonModel } from 'api'
import ChatController from 'controllers/ChatController'
import ChatMessage from './ChatMessage'


export default class ChatMessages extends Component {
  static propTypes = {
    userRequest: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired,
    postMessage: PropTypes.func.isRequired,
    postLocation: PropTypes.func.isRequired,
    postImage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.input = React.createRef()
    this.scrollBox = React.createRef()
    this.uploadInput = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.renderedMessagesCount != this.props.messages.length)
      this.scrollToBottom()
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  render() {
    let { messages, postMessage, userRequest } = this.props
    let inputButton = userRequest.inputButton || {}
    let respondable = inputButton.action || userRequest.userButton && userRequest.userButton.respondable
    let base = App.state.getObject('base') || '/home'
    let placeholder = inputButton.placeholder || "Add additional information..."

    let lastMessage = _.last(messages)
    if (lastMessage && lastMessage.input_disabled)
      respondable = false
    if(lastMessage && lastMessage.payment_smart_button_id && !lastMessage.paid)
      respondable = false

    return (
      <React.Fragment>
        <div className="chat-messages chat-body-scrollbox" ref={this.scrollBox} data-user-request-id={userRequest.id}>
          {
            messages == null || messages.length == 0 ?
              <div className="chat-no-messages">No messages yet</div> :
            messages.map(message =>
              <ChatMessage message={message} base={base} key={message.localId} messages={messages} />
            )
          }
        </div>
        {
          respondable &&
          <footer className="chat-typer">
            <hr />
            <div className="chat-input-row">
              <input
                className="form-control chat-input" ref={this.input} type="text"
                placeholder={placeholder}
                onKeyPress={e => e.key == 'Enter' && this.submitInput()}
              />
              <button className="btn chat-submit-btn" type="button" onClick={this.submitInput}>
                <FAIcon icon="faPaperPlane" />
              </button>
            </div>
          </footer>
        }
      </React.Fragment>
    )
  }

  submitInput = () => {
    let { userRequest } = this.props
    if (userRequest.inputButton) {
      this.runServerAction()
    } else {
      this.postNewMessage()
    }
  }

  runServerAction = async () => {
    let { userRequest } = this.props
    let { inputButton } = userRequest
    let text = this.input.current.value
    ChatController.pushToHistory(userRequest)
    await SmartButtonController.runServerAction(inputButton.buttonWithParams({ text }))
  }

  postNewMessage = () => {
    let text = this.input.current.value
    if (text != null && text.trim() != "") {
      this.props.postMessage(text)
      this.input.current.value = ''
      this.input.current.focus()
    }
  }

  shareLocation = e => {
    e.preventDefault()
    navigator.geolocation.getCurrentPosition(
      position => {
        this.props.postLocation(position.coords)
      },
      error => {
        console.warn('location request failed', error)
      }
    )
  }

  attachImage = e => {
    e.preventDefault()
    this.uploadInput.current.click()
  }

  attachmentSelected = e => {
    let file = this.uploadInput.current.files[0]
    if (file)
      this.props.postImage(file)
  }

  scrollToBottom() {
    this.renderedMessagesCount = this.props.messages.length
    if (this.scrollBox.current)
      this.scrollBox.current.scrollTop = this.scrollBox.current.scrollHeight
  }
}
