import { withErrorBoundary } from 'shared'
import { InfoNavbar } from 'layout'

class Information extends Component {
  render() {
    return (
        <div className="information-page">
          <InfoNavbar />
        </div>
    )
  }
}

export default withErrorBoundary(Information)
