import classNames from 'classnames'

export default function ParkCardBadge({ park }) {
  if (!park.badge) return null
  let bgClass = 'bg-primary'
  return (
    <div className={classNames('card-badge', bgClass)}>
      {park.badge}
    </div>
  )
}
