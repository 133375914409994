import { withErrorBoundary, Formatter } from 'shared'
import { absolutizePath } from 'shared/util'
import EventCardBadge     from './EventCardBadge'
import { eventDateTime, eventDateTitle, slideEventDateTime }  from './EventHelper'
import { MissingImage } from 'customizations'

function SlideEventCard({ event, base = 'events' }) {
  const imageUrl = event.resources && event.resources.length ? event.resources[0].preview : null

  return (
    <custom-card class="SlideEventCard">
      <Link to={`${absolutizePath(base)}/${event.targetUid}`} className='plain-link'>
        <div className="card">
          <div className="card-img-container">
            <img className="card-img-top"
              src={imageUrl || MissingImage}
              alt={event.name}
            />
            <EventCardBadge event={event} />
          </div>
          <div className="card-meta">
            <time className="card-note" style={{ display: 'flex' }} dateTime={event.open_time_local} title={eventDateTitle(event)}>
              {slideEventDateTime(event)}
            </time>
            <h5 className="card-heading">{event.name}</h5>
            <div className="card-subheading">{event.place_name}</div>
          </div>
        </div>
      </Link>
    </custom-card>
  )
}

export default withErrorBoundary(SlideEventCard, { className: 'custom-card' })
