import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus, faPlus,
  faChevronLeft,
  faEllipsisV, faEllipsisH,
  faPaperPlane,
  faTimes, faTrash, faTrashAlt,
  faAngleRight, faAngleLeft,
  faSpinner, faAsterisk, faSync,
  faCreditCard,
  faPhone, faPhoneSlash
} from '@fortawesome/free-solid-svg-icons'

import {
  faCcVisa, faCcMastercard, faCcDiscover, faCcJcb,
} from '@fortawesome/free-brands-svg-icons'

import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

const icons = {
  faMinus, faPlus,
  faChevronLeft,
  faEllipsisV, faEllipsisH,
  faPaperPlane,
  faTimes, faTrash, faTrashAlt,
  faAngleRight, faAngleLeft,
  faSpinner, faAsterisk, faSync,
  faUserCircle,
  faCreditCard, faCcVisa, faCcMastercard, faCcDiscover, faCcJcb,
  faPhone, faPhoneSlash
}

export default class FAIcon extends Component {
  render() {
    let { icon, ...otherProps } = this.props
    let iconResource = icons[icon]
    if (!iconResource)
      console.warn("Icon", icon, "is not imported!")

    return (
      <FontAwesomeIcon icon={iconResource} {...otherProps} />
    )
  }
}
