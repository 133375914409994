import { Spinner } from 'shared'
import { withErrorBoundary } from 'shared'
import { Backend }           from 'base'
import { TopLevelPage }      from 'ui'

class SquareCardEditor extends Component {
  state = { }

  componentDidMount() {
    this.setupSquarePayment()
  }

  setupSquarePayment = async () => {
    const squarePayment = Square.payments(application.squareApplicationId, null)
    const card = await squarePayment.card()
    await card.attach('#card-container')
    let eventHandler = async (event) => {
      event.preventDefault();
      try {
        const result = await card.tokenize();
        if (result.status === 'OK') {
          this.saveCardWithBackend(result.token)
        }
      } catch (e) {
        console.error(e);
      }
    };
    const cardButton = document.getElementById('card-button');
    cardButton.addEventListener('click', eventHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    if (this.state.done)
      return <Redirect to="/settings/payment" />

    return (
      <TopLevelPage className="SquarePayment row">
        <form id="form-container">
          <div id="card-container"></div>
          <button id="card-button" className="button-credit-card" type="button">
            Save Card
          </button>
          <p className="text-center mt-2">
            or <Link className="ui-btn ui-default" to="/settings/payment">Cancel</Link>
          </p>
        </form>
      </TopLevelPage>
    )
  }

  saveCardWithBackend = async (nonce) => {
    try {
      this.setState({ saving: true })
      console.info('Saving card nonce:', nonce)
      let apiResult = await Backend.post("/profile/square/credit_cards", { nonce })
      console.info('API result:', apiResult)
      localStorage.rlHasCard = true
      this.setState({ done: true, saving: false })
      this.forceUpdate()
    } catch (err) {
      console.warn("Stripe or API error", err)
      this.setState({ success: false, saving: false })
    }
  }
}

export default withErrorBoundary(SquareCardEditor)
