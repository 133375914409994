import { withErrorBoundary } from 'shared'

class PaymentResponsePage extends Component {

  render() {
    return (
      <div className="row">
        <h1>Payment Successful</h1>
      </div>
        
    )
  }
}
export default withErrorBoundary(PaymentResponsePage)
