import { ModelContainer, GlobalErrorMessage, connected } from 'shared'
import { Model } from 'api'
import { PolymorphicModal } from '.'

export default class TopLevelPage extends Component {
  componentDidMount() {
    App.state.setObject({ base: this.props.base })
  }

  render() {
    let { className, modelId, children, fullWidth, pageTitle } = this.props
    if (User.current && User.current.mustChangePassword)
      App.go('change-password')

    return (
      <div className={classNames(className, { container: !fullWidth })}>
        {
          pageTitle &&
          <div className="row page-header">
            <div className="col">
              <h5 className="page-title">{ pageTitle }</h5>
            </div>
          </div>
        }
        {children}
        {this.renderModal()}
        {App.state.error && <GlobalErrorMessage />}
      </div>
    )
  }

  renderModal() {
    let { modelId, base, ModalClass = PolymorphicModal } = this.props

    let activeModalType = application.state.getObject('activeModalType')
    if (activeModalType) return <ModalClass model={activeModalType} returnUrl={base} />

    if (modelId == null) return null
    if (modelId == 'chat') return <ModalClass model='chat' returnUrl={base} />
    if (modelId == 'guest-signup') return <ModalClass model='guest-signup' returnUrl={base} />

    let pureModelId = Model.extractPureModelId(modelId)
    let subroute = Model.extractSubroute(modelId)

    return <ModelContainer modelId={pureModelId}>
      { model => <ModalClass model={model} returnUrl={base} subroute={subroute} /> }
    </ModelContainer>
  }
}
