import Event from './event'
import Park  from './park'
import Club  from './club'
import Retail  from './retail'

export default class Model {
  static getClassForJSON(data) {
    if (data.type == 'event:details')    return Event
    if (data.type == 'event:occurrence') return Event
    if (data.type == 'place:details')    return Park
    if (data.type == 'club:details')     return Club
      if (data.type == 'retail:details')     return Retail
  }

  static fromJSON(data) {
    let type = Model.getClassForJSON(data)
    return type ? new type(data) : null
  }

  // event:123::reserve => event:123
  static extractPureModelId(identifier) {
    identifier = identifier || ''
    return _.first(identifier.split('::'))
  }

  static extractSubroute(identifier) {
    identifier = identifier || ''
    return identifier.includes('::') ? _.last(identifier.split('::')) : null
  }

  static extractSubrouteArgs(subroute, index = null) {
    let args = _.tail((subroute || '').split(':'))
    return index != null ? args && args[index] : args
  }
}
