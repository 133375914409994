import { Person, PeopleApi, ClubApi } from 'api'
import { TopLevelPage } from 'ui'
import { withErrorBoundary, connected, SearchBar, Formatter } from 'shared'
import { Spinner, NoResults } from 'shared'
import { absolutizePath } from 'shared/util'
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'
import SmartButtonApi  from 'api/smart-button-api'

class PeopleList extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.query = PeopleApi.paramsFor('index').q
    this.state = {
      currentPage: 1,
      totalPage: 1
    };
  }

  render() {
    let { id, club_id, args, place_checkins, reserved_event_id } = this.props.match.params
    let { smartButtons, reloadSmartButtons } = this.props
    let { currentPage } = this.state
    let { totalPage } = this.state

    let base
    if (club_id){
      base = args ? `people::${args}` : `people/club/${club_id}`
    }
    else if(place_checkins){
      base = args ? `people::${args}` : `people/place_checkins/${place_checkins}`
    }
    else if(reserved_event_id){
      base = args ? `people::${args}` : `people/event/${reserved_event_id}`
    }
    else{
      base = args ? `people::${args}` : `people`
    }

    let urlParams = new URLSearchParams(args)
    let userRoleId = urlParams.get('role')
    let club_models = ClubApi.kick('joined')
    let models = PeopleApi.kick('index', { 
                  q: this.query, user_role_id: userRoleId, club_id: club_id, place_checkins: place_checkins,
                  reserved_event_id: reserved_event_id, page: currentPage })
    
    if (models && models.length != 0){
      let lastIndex = models.length - 1
      totalPage = models[lastIndex].totalPages
    }

    return (
      <TopLevelPage className="People" modelId={id} base={'/' + base}>
        <div className="entity-smart-buttons smart-buttons">
          {smartButtons && smartButtons.map(smartButton =>
            <SmartButton {...{smartButton, reloadSmartButtons, base}} key={smartButton.key} returnPath={application.path} />
          )}
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <SearchBar onChange={this.queryDidChange} onSubmit={this.queryWouldSubmit} initialQuery={this.query} />
          </div>
        </div>
        <div className="entity-smart-buttons smart-buttons my-4 ">
          { club_models == null ? <Spinner /> :
            club_models.length == 0  ? <NoResults>You have not joined any club.</NoResults> :
            <div>
            {
              club_models.map(club =>
                // <Link text={`${club.name}-Members`} className="admin-purple inverted primary" key={`${club.id}`} to={`people/${club.id}`} />
                <Link to={`/people/club/${club.id}`} key={`${club.id}`} className='smart-button btn'>
                  {`${club.name}-Members`}
                </Link>
                )
            }
              <Link to={`/people`} className='btn btn-back'>
                {`Reset`}
              </Link>
            </div>
          }
        </div>
        <div>
          {
            models == null ? <Spinner /> :
            models.length == 0 ? <NoResults /> :
              <table className="person-table table">
                <tbody>
                  {
                    models.map(user =>
                      <tr key={user.uid} className="person-table-row">
                        <td className="person-avatar-cell">
                          {
                            user.picture &&
                            <Link to={`people/${user.uid}`} className='plain-link'>
                              <img src={user.picture} className="person-avatar rounded-circle" />
                            </Link>
                          }
                        </td>
                        <td>
                          {club_id ? (
                            <Link to={`${club_id}/${user.uid}`} className='plain-link'>
                              {user.name}
                            </Link>
                          ) : reserved_event_id ? (
                            <Link to={`${reserved_event_id}/${user.uid}`} className='plain-link'>
                              {user.name}
                            </Link>
                          ) : place_checkins ? (
                            <Link to={`${place_checkins}/${user.uid}`} className='plain-link'>
                              {user.name}
                            </Link>
                          ) : (
                            <Link to={`people/${user.uid}`} className='plain-link'>
                              {user.name}
                            </Link>
                          )}
                        </td>
                        <td>{user.phone}</td>
                        <td>{user.email}</td>
                        <td className="person-followers-cell">
                          {user.result_based_on_settings_identifier}
                        </td>
                      </tr>
                     )
                  }
                </tbody>
              </table>
           }
        </div>
        <div className="pagination-bar">
          <button disabled={currentPage === 1} onClick={this.handlePreviousPage}>Previous</button>
          <span>&nbsp; Page {currentPage} of {totalPage} &nbsp;</span>
          <button disabled={currentPage === totalPage} onClick={this.handleNextPage}>Next</button>
        </div>
      </TopLevelPage>
    )
  }

  handlePreviousPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
}

PeopleList.requestSmartButtons = (success) => {
  return SmartButtonApi.forUsers(success)
}

export default SmartButton.containerComponent(null, withErrorBoundary(connected(PeopleList)), { Source: PeopleList })
