import { Event, EventApi } from 'api'
import { TopLevelPage, ModelGrid } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import { withErrorBoundary, connected, SearchBar, Formatter } from 'shared'

Events = ({ match }) ->
  listId = match.params.listId
  modelId = match.params.id
  params = Event.parseEventListId(listId)
  models = EventApi.kick('list', params)
  base = "events/#{listId}"

  <TopLevelPage className="Events" modelId={modelId} base={'/' + base}>
    <ModelGrid models={models} base={base} />
    {App.showsButtonsOnEachFeed && <SmartButtonFeedList base={'/' + base} />}
  </TopLevelPage>

export default withErrorBoundary connected Events
