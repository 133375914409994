import ApiDispatcher from 'api/api-dispatcher'

export default class ModelContainer extends Component {
  static propTypes = {
    modelId: PropTypes.string
  }

  state = {  }

  componentDidMount() {
    this.loadModel()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modelId != this.props.modelId)
      this.loadModel()
  }

  render() {
    let { children } = this.props
    return children(this.cachedModel)
  }

  async loadModel() {
    let { modelId } = this.props
    if (this.state.loadingModelId == modelId)
      return

    let cached = this.cachedModel
    if (cached)
      this.forceUpdate()
    if (!cached || cached.needsReloading) {
      this.setState({ loadingModelId: modelId })
      await ApiDispatcher.loadModel(modelId)
      if (this.mounted !== false) this.setState({ loadingModelId: null })
    }
  }

  get cachedModel() {
    return App.state.entities[this.props.modelId]
  }
}
