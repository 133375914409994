import { ApiModel } from 'base'
import { pluralize } from 'shared/util'

export default class Person extends ApiModel {
  constructor(attributes) {
    super(attributes)
  }

  get uid() { return `person:${this.id}` }
  get name() { return `${this.first_name || ''} ${this.last_name || ''}`}
  
  get result_based_on_settings_identifier() {
    if (this.settings_identifier) {
       if (this.settings_identifier == 'user_role') return this.roles.join(', ')
       if (this.settings_identifier == 'follower_count') return pluralize(this.followers, "follower", "followers")
    }
    return null
  }
}
