import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Formatter, Spinner } from 'shared'
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'
import DatePicker from 'react-datepicker'

ParkServiceSelector = ({ park, base }) ->
  return <p>No services available...</p> unless park.hasServices

  <div className="park-service-selector">
    <h5 className="park_service_selector__title subpanel-title">Services</h5>
    <div className="park-service-selector__list">
      <table className="table table-sm table-borderless table-font-sm">
        <tbody>
        {
          park.services.map (service) ->
            <tr key={service.id}>
              <td>{service.name}</td>
              <td>{Formatter.amountFromCents(service.amount, {free: true})}</td>
              <td>{Formatter.duration(service.duration)}</td>
              <td className="text-right">
                <Link className='btn btn-outline-primary btn-sm' to={"#{base}/#{park.uid}::reserve:#{service.id}"}>
                  Select
                </Link>
              </td>
            </tr>
        }
        </tbody>
      </table>
    </div>
  </div>

export default ParkServiceSelector
