const API_ROOT = "/api/v1"

class Backend {
  get    (path, params, options) { return this.request('GET'   , path, params, options) }
  post   (path, params, options) { return this.request('POST'  , path, params, options) }
  put    (path, params, options) { return this.request('PUT'   , path, params, options) }
  destroy(path, params, options) { return this.request('DELETE', path, params, options) }

  request(method, path, data, options) {
    let url = path.startsWith(API_ROOT) ? path : `${API_ROOT}${path}`
    return $.ajax({ url, method, data, ...options,
      beforeSend: xhr => { if (App.authToken) xhr.setRequestHeader("Authorization", `Token token=${App.authToken}`) },
      error: xhr => { if (xhr.status == 401) { App.User.signOut(); window.location = '/app' } }
    })
  }
}

export default new Backend
