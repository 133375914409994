import Park           from './park'
import RetailApi        from './retail-api'
import SmartButtonApi from './smart-button-api'

export default class Retail extends Park {
  get uid() { return `retail:${this.id}` }
  get baseCollectionName() { return 'retails' }
  get opening_status_str() { return null }
  get isPark() { return false }
  get isClub() { return false }
  get isRetail() { return true }

  requestSmartButtons(success) { return SmartButtonApi.forRetail(this, success) }
  async enroll() { await RetailApi.enroll(this) }
  async leave() { await RetailApi.leave(this) }

  get needsReloading() { return this.eventIds == null }
}
