import ElipsisImage from 'images/ellipsis-50px'
import { Spinner, NoResults, FAIcon } from 'shared'
import { SmartButton } from 'ui/smart-buttons'

export default function ChatMessage({ message, base, messages }) {
  let { smartButton, text, upload, coords } = message
  let classes = classNames('chat-message', {
    'from-admin': message.fromAdmin,
    'from-user': message.fromUser,
    'smart': message.smartButton,
    'processing': message.processing,
  })

  let isFirstAdminMessage = message && message.isFirstAdminMessageInGroup && message.isFirstAdminMessageInGroup(messages)
  let isLastAdminMessage = message && message.isLastAdminMessageInGroup && message.isLastAdminMessageInGroup(messages)
  let currentIndex = messages.findIndex(m => m.id == message.id)
  let previousMessage = messages[currentIndex - 1]

  if (previousMessage)
    if (smartButton && previousMessage.paymentSmartButton && !previousMessage.paid)
      return null
  if (smartButton)
    return <div className={classes}>
             <SmartButton base={base} smartButton={smartButton}  />
           </div>

  return (
    <React.Fragment>
      {isFirstAdminMessage && <div className="chat-sender-info">{application.assets.get('app_name')}</div>}
      <div className={classes}>
        <span className="chat-message-avatar-holder">
          {isLastAdminMessage && <i className="chat-message-avatar admin"><FAIcon icon="faUserCircle" /></i>}
        </span>
        { message.paymentSmartButton && !message.paid ? <SmartButton base={base} smartButton={message.paymentSmartButton} paymentFor={`message_id:${message.id}`}  /> :
          <span className="chat-message-content">
            <span className="chat-message-text">
              {message.processing && <img src={ElipsisImage} className="chat-message-spinner" />}
              <span dangerouslySetInnerHTML={{__html: text && text.replace(/\n/g, "<br />")}}></span>
            </span>
            {upload && (
              upload.preview_url ?
                <div className="chat-message-image-wrapper">
                  <a href={upload.url} target="_blank">
                    <img src={upload.preview_url} className="chat-message-image" />
                  </a>
                </div> :
              upload.url ?
                <a href={upload.url} target='_blank'>File uploaded: {upload.filename}</a> :
              null
            )}
            {coords &&
              <span className="chat-message-shared-location">
                <a href={`https://www.google.com/maps?q=${coords.lat},${coords.lng}`} target="_blank">Location</a> shared
              </span>
            }
            <i className="chat-message-triangle"></i>
          </span>
        }
      </div>
    </React.Fragment>
  )
}
