import ElipsisImage from 'images/ellipsis-50px'

export default class SmartButtonLink extends Component {
  state = { }

  render() {
    let { to, className, disabled, click, children, text, inverted } = this.props
    let spinning = this.props.spinning || this.state.spinning
    let classes = classNames('smart-button btn', className, { processing: spinning, inverted: inverted && !spinning, disabled: disabled })

    return (
      <Link to={to} className={classes} onClick={() => this.onClick()}>
        <span className="smart-button-text">{children || text}</span>
        {spinning && <img src={ElipsisImage} className="smart-button-spinner" />}
      </Link>
    )
  }

  async onClick() {
    this.setState({ spinning: true })
    if (this.props.click)
      await this.props.click()
    this.setState({ spinning: false })
  }
}
