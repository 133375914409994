import { ChatMessage } from 'api'

export default class ChatController
  @historyStack = []

  @clean: ->
    App.state.deleteObject 'chat:showLast', 'chat:selectedUserRequestId', 'chat:smartButton'

  @presentSmartResponse: (response) ->
    @clean()
    App.state.setObject 'chat:thread': response
    App.openChat()

  @presentAdminSpinner: (smartButton = null) ->
    settings = messages: [ new ChatMessage(sender: 'admin', processing: true) ]
    if smartButton
      settings.userButton = smartButton
      settings.displayName = smartButton.hint?.title || smartButton.title

    ChatController.presentSmartResponse(settings)

  @pushToHistory: (response) ->
    @historyStack.unshift(response)
