import Api from 'base/api'
import Club from './club'

class ClubApi extends Api {
  collectionName = 'clubs'

  async index(params) {
    let json = await Backend.get('/clubs', params)
    return this.state.triggerNone(_ =>
      json.clubs.map(r => new Club(r))
    )
  }

  async show(id) {
    return this.receiveModel(await Backend.get(`/clubs/${id}`))
  }

  async enroll(club) {
    return this.receiveModel(await Backend.post(`/clubs/${club.id}/membership`))
  }

  async leave(club) {
    return this.receiveModel(await Backend.destroy(`/clubs/${club.id}/membership`))
  }

  async joined() {
    let json = await Backend.get('/clubs/joined')
    return this.state.triggerNone(_ =>
      json.clubs.map(r => new Club(r))
    )
  }

  receiveModel(json) {
    let model = new Club(json.club)
    this.state.updateModel(model)
    return model
  }
}

export default new ClubApi
