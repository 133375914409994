import { withErrorBoundary } from 'shared'
import { TopLevelPage }      from 'ui'
import { Backend }           from 'base'
import { Spinner }           from 'shared'
import { withRouter } from "react-router";

class FranCardEditor extends Component {

  state = { }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    let reservation_id = this.props.reservationId
    let amount = this.props.amount
    let date = this.props.date
    let time = this.props.time
    let service_id = this.props.service_id
    let mob_params = location.hash.split('?').pop()
    let param = amount ? `${reservation_id.split(':')[0]}_id=${reservation_id}&amount=${amount}&date=${date}&time=${time}&service_id=${service_id}` : reservation_id.includes('sb:') ? `smart_button_id=${reservation_id}&amount_p=${location.hash.split('/').pop()}` :`${reservation_id.split(':')[0]}_id=${reservation_id}`
    let new_path = ''
    if (mob_params.includes('mobile')) {
      param = `${param}&${mob_params}`
      new_path = reservation_id ? `/profile/credit_cards?${param}` : `/profile/credit_cards?${mob_params}`
    } else {
      new_path = reservation_id ? `/profile/credit_cards?${param}` : '/profile/credit_cards'
    }
    let path = new_path
    let { fran_token } = await Backend.get(path)
    this.setState({token: fran_token })
  }

  render() {
    return (
      <TopLevelPage className="row">
        {
          this.state.token == null ? <Spinner /> :
          <iframe src={this.state.token} name="iframe_a" height="400px" width="100%" title="Iframe Example">Add</iframe>
        }
      </TopLevelPage>
    )
  }
}

export default withErrorBoundary(FranCardEditor)
