import { Formatter } from 'shared'
import { ApiModel } from 'base'
import SmartButton from './smart-button'

export default class ChatMessage extends ApiModel {
  constructor(attributes) {
    super(attributes)
    if (this.smart_button) {
      this.smartButton = new SmartButton({ ...this.smart_button, embedded: true, parentUserRequestId: this.parentUserRequestId })
      delete this.smart_button
    }
    if(this.payment_smart_button){
      this.paymentSmartButton = new SmartButton(this.payment_smart_button)
      delete this.payment_smart_button
    }
    this.sender = this.sender || 'user'
  }

  get fromAdmin() { return this.smartButton == null && this.sender == 'admin' }
  get fromUser()  { return this.smartButton == null && this.sender == 'user' }

  get localId() {
    if (this.id) return this.id
    if (this.created_at) return `${this.text}:${this.created_at}`
    this.uuid = this.uuid || `${Date.now()}${Math.floor(Math.random() * 10000000)}}`
    return this.uuid
  }

  isFirstAdminMessageInGroup(allMessages) {
    if (!this.fromAdmin) return false

    let index = allMessages.indexOf(this)
    if (index == null) return false
    if (index == 0) return true

    let prev = allMessages[index - 1]
    if (prev.fromAdmin) return false
    return true
  }

  isLastAdminMessageInGroup(allMessages) {
    if (!this.fromAdmin) return false

    let index = allMessages.indexOf(this)
    if (index == null) return false
    if (index == allMessages.length - 1) return true

    let next = allMessages[index + 1]
    if (next.fromAdmin) return false
    return true
  }
}
