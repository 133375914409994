import { Modal, withErrorBoundary, connected, Spinner, NoResults, FAIcon } from 'shared'
import { UserRequestApi, SmartButtonUserRequest } from 'api'
import ChatMessages from './ChatMessages'
import ChatTopics   from './ChatTopics'

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = { expanded: props.embedded }
    App.chat = this
  }

  render() {
    let { embedded } = this.props
    let { expanded, blinking } = this.state
    let userRequests = UserRequestApi.cached('index')
    let isLoading = userRequests == null

    let shouldShowLastUserRequest = App.state.getObject('chat:showLast')
    if (shouldShowLastUserRequest && userRequests && userRequests[0]) {
      if (!App.state.getObject('chat:selectedUserRequestId')) {
        App.state.setObjectSilent({ 'chat:selectedUserRequestId': userRequests[0].uid })
        App.state.deleteObjectSilent('chat:showLast')
      }
    }

    let selectedUserRequest = App.state.getModelByKey('chat:selectedUserRequestId')

    let base = App.state.getObject('base') || '/home'

    let smartButton = App.state.getObject('chat:smartButton')
    if (smartButton)
      selectedUserRequest = new SmartButtonUserRequest(smartButton)

    if (selectedUserRequest) {
      App.state.deleteObjectSilent('chat:thread')
    } else {
      let thread = App.state.getObject('chat:thread')
      if (thread)
        selectedUserRequest = thread
    }

    let classes = classNames('chat', {
      collapsed: !expanded,
      embedded: embedded,
      global: !embedded,
      blinking: blinking,
      loading: isLoading,
      'shows-messages': selectedUserRequest != null,
      'shows-topics':  selectedUserRequest == null && userRequests != null,
      'is-home': selectedUserRequest == null,
    })

    return (
      <div className={classes} onAnimationEnd={this.animationDidEnd}>
        <header className="chat-header">
          <i className="chat-title-icon chat-home-icon" onClick={this.backButtonClicked}>
            <FAIcon icon={this.isClosable ? 'faTimes' : 'faChevronLeft'} />
          </i>
          <span className={`chat-title ${selectedUserRequest ? 'clickable' : ''}`} onClick={this.backButtonClicked}>
            {selectedUserRequest ? selectedUserRequest.displayName : "Select Open Requests"}
          </span>
          {embedded ?
            <Modal.CloseButton /> :
            <i className="chat-title-icon" onClick={this.toggle}>
              <FAIcon icon={expanded ? 'faMinus' : 'faPlus'} />
            </i>
          }
        </header>
        <div className="chat-body">
        {
          isLoading ?
            <Spinner color="tertiary" text="Loading..." /> :
            selectedUserRequest ?
              <ChatMessages
                messages={selectedUserRequest.messages}
                userRequest={selectedUserRequest}
                postMessage={this.postMessage}
                postLocation={this.postLocation}
                postImage={this.postImage}
                base={base}
              /> :
              userRequests.length ?
                <ChatTopics userRequests={userRequests} onSelect={this.selectUserRequest} /> :
                <NoResults>No Conversations Yet.</NoResults>
        }
        </div>
        <div className="chat-rounding-compensator"></div>
      </div>
    )
  }

  toggle      = () => this.setState({ expanded: !this.state.expanded })
  collapse    = () => this.setState({ expanded: false })
  expand      = () => this.setState({ expanded: true })
  blink       = () => this.setState({ blinking: true })
  cancelBlink = () => this.setState({ blinking: false })
  animationDidEnd = () => { this.cancelBlink() }
  selectUserRequest = userRequest => App.state.setObject({ 'chat:selectedUserRequestId': userRequest.uid })

  get isClosable() {
    let request = App.state.getModelByKey('chat:selectedUserRequestId')
    let button = request ? request.userButton : App.state.getObject('chat:smartButton')
    let buttonType = button && button.kind
    return !['admin-request', 'form'].includes(buttonType)
  }

  backButtonClicked = () => {
    if (this.isClosable) {
      App.state.deleteObject('chat:selectedUserRequestId')
      Modal.current.hide()
    } else {
      this.openMenu()
    }
  }

  openMenu = () => {
    let smartButton = App.state.getObject('chat:smartButton')
    if (smartButton && smartButton.parent)
      App.state.setObject({ 'chat:smartButton': smartButton.parent })
    else
      App.state.deleteObject('chat:selectedUserRequestId', 'chat:smartButton')
  }


  postMessage = text => {
    UserRequestApi.postMessage(App.state.getModelByKey('chat:selectedUserRequestId'), text)
  }

  postLocation = coords => {
    UserRequestApi.postLocation(App.state.getModelByKey('chat:selectedUserRequestId'), coords)
  }

  postImage = image => {
    UserRequestApi.postImage(App.state.getModelByKey('chat:selectedUserRequestId'), image)
  }
}


class ChatContainer extends Component {
  componentDidMount() {
    this.loadUserRequests()
    this.timer = setInterval(this.timerTick, process.env.RAILS_ENV == 'development' ? 5000 : 5000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() { return <Chat {...this.props} /> }

  async loadUserRequests() {
    UserRequestApi.refresh('index')
    let currentRequest = App.state.getModelByKey('chat:selectedUserRequestId')
    if (currentRequest) {
      UserRequestApi.reload(currentRequest)
    }
  }

  timerTick = () => { this.loadUserRequests() }
}


export default withErrorBoundary(
  connected(ChatContainer, { trackedScopes: ["base", "user-requests:index", "user-request:", "chat:"] }),
  { className: 'chat' }
)
