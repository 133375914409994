import SquareCardEditor   from './SquareCardEditor'
import FranCardEditor   from './FranCardEditor'
import StripeCardEditor   from './StripeCardEditor'

export default function PaymentCardEditor(event) {
	if (event.location.search.match('mobile=true') && event.location.pathname.match('park')) {
		let param = event.location.pathname.split('park:').pop().split('/')
		let reservation = `park:${param[0]}`
		let amount = param[1]
		let date = param[2]
		let time = param[3]
		let service = param[4]
		let tickets = param[5]

  	return document.getElementById('square-js') ? <SquareCardEditor /> : <FranCardEditor reservationId={reservation} amount={amount} date={date} time={time} service_id={service} tickets={tickets} />
	} else {
  	return document.getElementById('square-js') ? <SquareCardEditor /> : <FranCardEditor reservationId={event.match.params.reservationId} amount={event.match.params.amountArg} date={event.match.params.dateId} time={event.match.params.timeId} service_id={event.match.params.serviceId} tickets={event.match.params.ticketTypes}/>
	}
}
