import SmartButton from './SmartButton'
import { SmartButton as SmartButtonModel } from 'api'

const smartButtonContainer = (sourceModelName, View, { Source } = { }) =>
  class SmartButtonContainer extends Component {
    state = { smartButtons: null }

    componentDidMount() {
      this.reload()
    }

    componentDidUpdate(prevProps) {
      if (this.props[sourceModelName] && prevProps[sourceModelName] &&
          (this.props[sourceModelName].id != prevProps[sourceModelName].id ||
          this.props[sourceModelName].updated_at != prevProps[sourceModelName].updated_at)) {
        this.reload()
      }
    }

    componentWillUnmount() {
      this.xhr && this.xhr.abort()
      this.mounted = false
    }

    render() {
      return <View {...this.props} {...this.state} reloadSmartButtons={this.reload.bind(this)} />
    }

    reload() {
      let source = Source || this.props[sourceModelName]
      this.currentSource = source
      if (source.requestSmartButtons)
        return this.xhr = source.requestSmartButtons(smartButtons => {
          delete this.currentSource
          if (this.mounted !== false)
            this.setState({ smartButtons })
        })
    }
  }


const SmartButtonList = smartButtonContainer('smartButtonSource', ({smartButtons, reloadSmartButtons, base, className}) => {
  if (smartButtons == null || smartButtons.length == 0 || (App.embed == true)) return null
  return (
    <div className={`smart-buttons page-level ${className || ''}`}>
      {smartButtons.map(smartButton =>
        <SmartButton {...{smartButton, reloadSmartButtons, base}} key={smartButton.key} />
      )}
    </div>
  )
})

const SmartButtonFeedList = ({base, source} = {}) =>
  <div className="home-smart-buttons">
    <SmartButtonList smartButtonSource={source || SmartButtonModel.feedSource} base={base} />
  </div>


export { smartButtonContainer, SmartButtonList, SmartButtonFeedList }
