import { withErrorBoundary, Formatter } from 'shared'
import { SmartButtonStub } from 'ui/smart-buttons'
import { LogoImage } from 'customizations'
import { User } from 'api'
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'

class OptInSignupPage extends Component
  state:
    user:
      email: ''
      phone: ''
      verification_code: ''
    settings: {}
    message: ''
    errors: null
    sign_up_success: false
    vc_success: false
    submitting: false

  componentDidMount: ->
    @loadData()

  loadData: ->
    @setState settings: await Backend.get('/signup_settings')

  inputChanged: (e) =>
    { name, value } = e.target
    @setState user: { @state.user..., "#{name}": value }, errors: null

  inputChangednew: (value) =>
    @setState user: { @state.user..., phone: value }, errors: null

  submit: (e) =>
    @setState submitting: true
    e.preventDefault()
    try
      res = await Backend.post('/users/opt_in_signup', @state.user)
      @setState errors: null, sign_up_success: true, message: res.message, vc_success: false
    catch xhr
      if xhr.status in [400..499]
        @setState errors: xhr.responseJSON.errors
    finally
      @setState submitting: false

  submitVerificationCode: (e) =>
    @setState submitting: true
    e.preventDefault()
    try
      res = await Backend.post('/users/verify_code', @state.user)
      @setState errors: null, vc_success: true, sign_up_success: true
    catch xhr
      if xhr.status in [400..499]
        @setState errors: xhr.responseJSON.errors, message: null
    finally
      @setState submitting: false

  renderForm: ->
    settings = @state.settings || {}
    <form className="signup--form" onSubmit={@submit}>
      <p dangerouslySetInnerHTML={{ __html: Formatter.simpleFormat(settings.opt_in_header_text || 'Please fill the form below:') }}></p>
      {
        if @state.errors
          <p key={@state.errors} className="alert alert-danger alert-mini text-center">{@state.errors}</p>
      }
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--email">Please verify the email on your account</label>
            <input type="email" className="form-control" id="signup--email" name="email" value={@state.user.email} required onChange={@inputChanged} />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--phone">Please verify the phone number on your account</label>
            <Input
              placeholder="Enter phone number"
              type="tel"
              country="US"
              international
              withCountryCallingCode
              maxLength={15}
              className="form-control"
              id="signup--phone"
              name="phone"
              value={@state.user.phone}
              onChange={@inputChangednew}
              required
            />
          </div>
        </div>
      </div>
      <div className="form-actions text-center">
        <SmartButtonStub text="Sign Up and Receive Verification Code" buttonType="submit" className="inverted primary" spinning={@state.submitting} />
      </div>
    </form>

  renderVerificationCodeForm: ->
    settings = @state.settings || {}
    <form className="verification--form" onSubmit={@submitVerificationCode}>
      <p dangerouslySetInnerHTML={{ __html: Formatter.simpleFormat(settings.opt_in_header_text || 'Please fill the form below:') }}></p>
      {
        if @state.errors
          <p key={@state.errors} className="alert alert-danger alert-mini text-center">{@state.errors}</p>
      }
      {
        if @state.message
          <p className="alert alert-success text-center">{@state.message}</p>
      }
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--email">Please verify the email on your account</label>
            <input type="email" className="form-control" id="signup--email" name="email" value={@state.user.email} required onChange={@inputChanged} disabled />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="signup--phone">Please verify the phone number on your account</label>
            <Input
              placeholder="Enter phone number"
              type="tel"
              country="US"
              international
              withCountryCallingCode
              maxLength={15}
              className="form-control"
              id="signup--phone"
              name="phone"
              value={@state.user.phone}
              onChange={@inputChangednew}
              required
              disabled
            />
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label htmlFor="verification_code">Please Enter Your Verification Code</label>
            <input type="text" className="form-control" id="verification_code" name="verification_code" value={@state.user.verification_code} required onChange={@inputChanged} />
          </div>
        </div>
      </div>
      <div className="form-actions text-center">
        <SmartButtonStub text="Confirm Verification Code" buttonType="submit" className="inverted primary" spinning={@state.submitting} />
      </div>
    </form>

  renderSuccess: ->
    <p className="alert alert-success text-center">
      {
        "You have successfully opted in to receive messages.."
      }
    </p>

  render: ->
    <div className="Login Signup">
      <div className="left d-none d-sm-block">
        <div className="left-overlay"></div>
        <p className="login-copyright">{application.assets.get('copyright')}</p>
      </div>
      <div className="right">
        <img src={LogoImage} className='logo-image mb-2' />
        <section className="form">
          {
            if @state.sign_up_success and @state.vc_success
              @renderSuccess()
            else if @state.sign_up_success and not @state.vc_success
              @renderVerificationCodeForm()
            else
              @renderForm()
          }
        </section>
      </div>
    </div>

export default withErrorBoundary(OptInSignupPage)
