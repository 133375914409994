import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class SearchBar extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    initialQuery: PropTypes.string,
  }

  render() {
    let { initialQuery } = this.props

    return (
      <div className="search-bar">
        <input
          className="search-bar-input form-control"
          type="search" placeholder="Search" defaultValue={initialQuery}
          onChange={this.queryDidChange}
          onKeyPress={e => e.key == 'Enter' && this.queryWouldSubmit()}
        />
        <i className="search-bar-search-icon" onClick={this.queryWouldSubmit}>
          <FontAwesomeIcon icon={faSearch} />
        </i>
      </div>
    )
  }

  queryDidChange = e => {
    let query = e.target.value
    this.queryWouldSubmitThrottled.cancel()
    this.queryWouldSubmitThrottled()
    this.props.onChange(query)
  }

  queryWouldSubmit = () => {
    this.props.onSubmit()
  }

  queryWouldSubmitThrottled = _.throttle(this.queryWouldSubmit, 500, { leading: false, trailing: true })
}
