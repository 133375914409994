import { ApiModel } from 'base'
import ChatMessage  from './chat-message'
import SmartButton  from './smart-button'

export default class SmartResponse extends ApiModel
  constructor: (attributes) ->
    super attributes
    @items = (new ChatMessage(item) for item in @items) if @items
    @sender ?= 'user'
    if @input_button
      @inputButton = new SmartButton(@input_button)
      delete @input_button

  @prop 'displayName', -> @ui_title
  @prop 'messages', -> @items
  @prop 'shouldOpenNewChat', -> @ui_action is 'new-chat'
  @prop 'shouldOpenOverlay', -> @ui_action is 'modal'
