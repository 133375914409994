import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Formatter, Spinner } from 'shared'
import DatePicker from 'react-datepicker';

export default class ParkReservationCalendar extends Component {
  state = { date: null, time: null, timeSlots: null, soldOutDates: [] }
  loadedSoldOutMonths = {}

  render() {
    let { park, date, serviceId, service, base } = this.props
    let { soldOutDates } = this.state
    let updateDate = async date => { application.go(application.router.parkReservation(base, park, service, date)) }
    let resetDate = () => { application.go(application.router.parkReservation(base, park, service)) }
    let resetService = () => { application.go(application.router.parkReservation(base, park)) }

    this.loadSoldOutDates(date && date.toDate() || application.todayDate)
    let monthChanged = date => { this.loadSoldOutDates(date) }

    return (
      <div className="park-reservation-datepicker custom-calendar">
        <h5 className='custom-calendar-heading'>Reserve this Location</h5>
        <div className="datepicker-wrapper">
          <DatePicker
            inline
            selected={date && date.toDate()}
            onChange={updateDate}
            minDate={application.todayDate} maxDate={moment(application.todayDate).add(1, 'year').toDate()}
            excludeDates={park.closedDateObjects}
            dayClassName={date => soldOutDates.includes(moment(date).format('YYYY-MM-DD')) ? 'day-booked' : undefined}
            // dayClassName={date => park.bookedOnDate(date) ? 'day-booked' : undefined}
            onMonthChange={monthChanged}
          />
          <div className="text-muted text-sm text-center">
            {service &&
              <div>
                <span className="icon-btn-with-text">
                  {service.name}
                  <i className="icon-btn" onClick={resetService} title="Reset service">
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </i>
                </span>
              </div>
            }
            {date &&
              <div>
                <span className="icon-btn-with-text">
                  {Formatter.dateUS(date)}
                  <i className="icon-btn" onClick={resetDate} title="Reset dates">
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </i>
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  loadSoldOutDates = async date => {
    let { park, serviceId } = this.props
    let { soldOutDates } = this.state
    let month = moment(date).format('YYYY-MM')
    let key = [month, serviceId].join('__')
    if (!this.loadedSoldOutMonths[key]) {
      this.loadedSoldOutMonths[key] = true
      let reservedDates = await ParkApi.getFullyReservedDates(park, month, serviceId)
      this.setState({ soldOutDates: soldOutDates.concat(reservedDates) })
    }
  }
}
