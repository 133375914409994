import Park           from './park'
import ClubApi        from './club-api'
import SmartButtonApi from './smart-button-api'

export default class Club extends Park {
  get uid() { return `club:${this.id}` }
  get baseCollectionName() { return 'clubs' }
  get opening_status_str() { return null }
  get isPark() { return false }
  get isClub() { return true }

  requestSmartButtons(success) { return SmartButtonApi.forClub(this, success) }
  async enroll() { await ClubApi.enroll(this) }
  async leave() { await ClubApi.leave(this) }

  get needsReloading() { return this.eventIds == null }
}
