import Api        from 'base/api'
import EventApi   from './event-api'
import MenuApi    from './menu-api'

class GuestPassApi extends Api
  postEvent: (event) ->
    await Backend.post "/guest_pass/events", event_id: event.id
    @resetMenus()

  postPlace: (place) ->
    await Backend.post "/guest_pass/places", place_id: place.id
    @resetMenus()

  resetMenus: ->
    EventApi.reset('mineGrouped')
    MenuApi.reset('saved_amenities')

export default new GuestPassApi
