import { Formatter } from 'shared'

export function eventPrices(event) {
  return event.ticket_settings.filter(s => s.amount).map(s => Formatter.amountFromCents(s.amount)).join('/')
}

export function eventHours(event) {
  if (!event.open_time_local) return null
  if (event.isMultiday) return null
  return event.close_time_local ?
    `${Formatter.hhmmToAmpm(event.open_time_local)}–${Formatter.hhmmToAmpm(event.close_time_local)}` :
    `${Formatter.hhmmToAmpm(event.open_time_local)}`
}

export function eventDate(event, format = 'usDateYYYY') {
  if (!event.date) return null
  if (event.isMultiday)
    return `${Formatter.dateOrToday(event.date, format)}–${Formatter.dateOrToday(event.end_date, format)}`
  return Formatter.dateOrToday(event.date, format)
}

export function eventDateTitle(event) {
  if (!event.date) return null
  if (event.isMultiday) return eventDate(event, 'longest')
  return Formatter.usDateTime(event.open_datetime)
}

export function eventDateTime(event) {
  if (event.date || event.open_time_local)
    return `${eventDate(event)} ${eventHours(event) || ''}`
}

export function slideEventHours(event) {
  if (!event.open_time) return null
  if (event.category == 'multiday') return null
  return event.close_time ?
    `${Formatter.hhmmToAmpm(event.open_time)}–${Formatter.hhmmToAmpm(event.close_time)}` :
    `${Formatter.hhmmToAmpm(event.open_time)}`
}

export function slideEventDate(event, format = 'usDateYYYY') {
  if (!event.date) return null
  if (event.category == 'multiday')
    return `${Formatter.dateOrToday(event.date, format)}–${Formatter.dateOrToday(event.end_date, format)}`
  return Formatter.dateOrToday(event.date, format)
}

export function slideEventDateTime(event) {
  if (event.date || event.open_time_local)
    return `${slideEventDate(event)} ${slideEventHours(event) || ''}`
}
