import { EventApi } from 'api'
import { withErrorBoundary, connected, SearchBar, Formatter } from 'shared'
import { ModelGrid, TopLevelPage } from 'ui'
import { SmartButtonFeedList } from 'ui/smart-buttons'
import CommunityDatePicker from './CommunityDatePicker'

class CommunityCalendar extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    let params = EventApi.paramsFor('all')
    this.query = params.q
    this.state = {
      startDate: (params.from || params.date) && moment(params.from || params.date),
      endDate: params.till && moment(params.till)
    }
  }

  render() {
    let { startDate, endDate } = this.state
    let dateParams = startDate && !endDate ? { date: startDate } : { from: startDate, till: endDate }
    let models = EventApi.kick('all', { q: this.query, ...dateParams })

    return (
      <TopLevelPage className="Community" modelId={this.props.match.params.id} base="/community">
        <div className="row">
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <SearchBar onChange={this.queryDidChange} onSubmit={this.queryWouldSubmit} initialQuery={this.query} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-9 order-2 order-md-1">
            <ModelGrid models={models} base={'community'} />
          </div>
          <div className="col-md-3 order-1 order-md-2">
            <div className="list-calendar-panel">
              <CommunityDatePicker startDate={startDate} endDate={endDate} onChange={this.datesDidChange} />
            </div>
          </div>
        </div>
        {App.showsButtonsOnEachFeed && <SmartButtonFeedList base="/community" />}
      </TopLevelPage>
    )
  }

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
  datesDidChange = dates => { this.setState(dates) }
}

export default withErrorBoundary(connected(CommunityCalendar))
