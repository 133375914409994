import { Api } from 'base'
import UserRequest from './user-request'

const REPEAT_TIMEOUT = 100

class UserRequestApi extends Api {
  collectionName = 'user-requests'

  async index(params) {
    params = params || {}
    params.include_hidden = true
    return (await Backend.get('/user_requests', params)).user_requests.map(r => new UserRequest(r))
  }

  async postMessage(userRequest, text) {
    userRequest.addMessage(text)
    this.state.updateModel(userRequest)
    return this.convertRemoteError(async () => {
      let json = await Backend.post(`/user_requests/${userRequest.id}/messages`, {text})
      return this.updateOrReload(json, userRequest, REPEAT_TIMEOUT)
    })
  }

  async postLocation(userRequest, geolocation) {
    let coords = { lat: geolocation.latitude, lng: geolocation.longitude, accuracy: geolocation.accuracy }
    return this.convertRemoteError(async () => {
      let json = await Backend.post(`/user_requests/${userRequest.id}/messages`, { coords })
      return this.updateModel(json)
    })
  }

  async postImage(userRequest, image) {
    return this.convertRemoteError(async () => {
      let endpoint = `/user_requests/${userRequest.id}/uploads?filename=${encodeURIComponent(image.name)}`
      let json = await Backend.post(endpoint, image, {processData: false, contentType: false})
      return this.updateModel(json)
    })
  }

  async postDownloadConfirmation(userRequest, fileButton) {
    return this.convertRemoteError(async () => {
      let endpoint = `/user_requests/${userRequest.id}/confirm_download`
      let json = await Backend.post(endpoint, {file_button_id: fileButton.id})
      return this.updateModel(json)
    })
  }

  async undo(userRequest) {
    return this.convertRemoteError(async () => {
      let json = await Backend.post(`/user_requests/${userRequest.id}/run/undo`)
      return this.updateModel(json)
    })
  }

  async click(userRequest, smartButton) {
    if (!userRequest.id || !smartButton.id) return
    return this.convertRemoteError(async () => {
      let json = await Backend.put(`/user_requests/${userRequest.id}`, {smart_button_id: smartButton.id})
      return this.updateOrReload(json, userRequest, REPEAT_TIMEOUT)
    })
  }

  async reload(userRequest, repeatTimeout = REPEAT_TIMEOUT) {
    return this.convertRemoteError(async () => {
      let json = await Backend.get(`/user_requests/${userRequest.id}`)
      return this.updateOrReload(json, userRequest, repeatTimeout)
    })
  }

  async create(smartButton) {
    if (!smartButton.id) return
    return this.convertRemoteError(async () => {
      let json = await Backend.post(`/user_requests`, {smart_button_id: smartButton.id})
      return this.updateOrReload(json, json.user_request, REPEAT_TIMEOUT)
    })
  }

  updateModel(json) {
    let model = new UserRequest(json.user_request)
    this.state.updateModel(model)
    return model
  }

  updateOrReload(json, userRequest, repeatTimeout) {
    if (json.must_reload) {
      setTimeout(() => { this.reload(userRequest, repeatTimeout * 2) }, repeatTimeout)
    }
    return this.updateModel(json)
  }
}

export default new UserRequestApi
