import { MissingImage } from 'customizations'
import { Modal, withErrorBoundary, GoogleStaticMap, Formatter } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import ImageGallery from 'react-image-gallery'

# https://github.com/xiaolin/react-image-gallery
# https://github.com/leandrowd/react-responsive-carousel
# https://github.com/akiran/react-slick
# https://github.com/FormidableLabs/nuka-carousel
class ImageGalleryModal extends Component
  render: ->
    { gallery } = @props

    images = gallery.images.map (item) ->
      { original: item.image_url, thumbnail: item.thumbnail_url }

    <div className="GalleryModal custom-modal">
      <div className="modal-body">
        <div style={width: '100%'}>
          <h1 className="GalleryModal-title">{gallery.title}</h1>
          <ImageGallery items={images}
            infinite={true} lazyLoad={true}
            showFullscreenButton={false} showPlayButton={false}
            renderItem={@renderItem}
          />
        </div>
      </div>
    </div>

  renderItem: (item) ->
    <div className='image-gallery-image'>
      <div className="image-gallery-image-div" style={ backgroundImage: "url(#{item.original})" } />
    </div>

export default withErrorBoundary(ImageGalleryModal)
