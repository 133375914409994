import { LogoImage } from 'customizations'
import { User } from 'api'
import { withErrorBoundary, Spinner } from 'shared'
import { SmartButtonStub, SmartButtonLink } from 'ui/smart-buttons'

class LoginPage extends Component {
  state = { email: '', password: '' }

  componentDidMount() {
    if (!User.signedIn && App.forceGuestMode) {
      User.guestExperienceSignIn()
    }
  }

  render() {
    return (
      <div className="Login">
        <div className="left d-none d-sm-block">
          <div className="left-overlay"></div>
          <p className="login-copyright">{application.assets.get('copyright')}</p>
        </div>
        <div className="right">
          {App.forceGuestMode ? <Spinner color="tertiary" /> : this.renderRightPane()}
        </div>
      </div>
    )
  }

  renderRightPane() {
    let loginEnabled = App.loginOption.includes('user')
    let guestsEnabled = App.loginOption.includes('guest')
    let signupEnabled = App.data.features.sign_up_enabled

    return (
      <>
        <img src={LogoImage} className='logo-image mb-2' />
        <section className="form">
          <h3 className="mb-3 d-none">Sign In</h3>

          { loginEnabled &&
            <form>
              <div className="labeled-inline-field">
                <label>Login</label>
                <input type="email" className="form-control"
                  placeholder="Email"
                  autoComplete="email"
                  name="email" value={this.state.email}
                  onChange={this.inputChanged}
                  onKeyPress={e => e.key == 'Enter' && this.logIn()}
                />
              </div>
              <div className="labeled-inline-field">
                <label>Password</label>
                <input type="password" className="form-control"
                  placeholder="Password"
                  autoComplete="current-password"
                  name="password" value={this.state.password}
                  onChange={this.inputChanged} />
              </div>
            </form>
          }

          { this.state.error &&
            <p className="alert alert-danger text-center">{this.state.error}</p>
          }

          { loginEnabled &&
            <p className="links text-right mb-2">
              <a className="forgot-password-link" href="/u/password/new">Forgot Password?</a>
            </p>            
          }

          <div className="text-center">
            {
              loginEnabled &&
              <SmartButtonStub text="Sign In" buttonType="button" className="my-2 admin-purple px-5 inverted primary" click={this.logIn} />
            }
            {
              loginEnabled && signupEnabled &&
              <SmartButtonLink text="Create my Account" className="my-2 admin-purple px-5 inverted primary" to="signup" />
            }
            {
              guestsEnabled &&
              <SmartButtonStub text="View Guest Experience" buttonType="button" className="my-2 admin-purple px-5 inverted primary" click={User.guestExperienceSignIn} />
            }
          </div>
        </section>
      </>
    )
  }

  logIn = async () => {
    let { email, password } = this.state
    this.doLogin('/auth', {email, password, device_type: 'browser'})
  }

  // guestPassLogin = async () => {
  //   if (User.signedIn && User.guest)
  //     App.go('/home')
  //   else
  //     this.doLogin('/auth/pass', {device_type: 'browser'})
  // }

  doLogin = async (url, params) => {
    try {
      let res = await Backend.post(url, params)
      console.info('auth as', res.user)
      User.signIn(res.user)
      this.setState({ error: null })
      window.location = '/app'
    } catch(xhr) {
      console.warn('auth failed', xhr)
      if (xhr.status >= 400 && xhr.status < 500) {
        this.setState({ error: xhr.responseJSON.message })
      }
    }
  }

  inputChanged = (e) => {
    let { name, value } = e.target
    this.setState({ [name]: value, error: null })
  }
}

export default withErrorBoundary(LoginPage)
