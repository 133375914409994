export default class AppState {
  constructor() {
    this.entities = {}
    this.collections = {}
    this.progresses = {}
    this.objects = {}
  }

  updated({ info = null, scope = null } = { }) {
    if (this.__blockEvents) return
    // console.info('%cupdated', 'color: blue', scope || info)
    this.trigger('change', scope)
  }

  getModel(uid) {
    return this.entities[uid]
  }

  getModelByKey(key) {
    return this.getModel(this.getObject(key))
  }

  updateModel(model) {
    this.entities[model.uid] = model
    this.updated({ scope: model.uid })
  }

  triggerOnce(block) {
    let results = this.triggerNone()
    this.updated()
    return results
  }

  triggerNone(block) {
    this.__blockEvents = true
    let result = block()
    delete this.__blockEvents
    return result
  }

  updateModels(models, {trigger = true} = {}) {
    for (let model of models)
      this.entities[model.uid] = model
    if (trigger)
      this.updated({ scope: models.map(m => m.uid) })
  }

  getEvent(id) {
    return this.entities[`event:${id}`]
  }

  setError(error) {
    this.error = error
    this.updated({ scope: 'error' })
  }

  get overlay() { return this.objects.overlay }

  deleteObjectSilent(...keys) {
    for (let key of keys)
      delete this.objects[key]
  }

  deleteObject(...keys) {
    this.deleteObjectSilent(...keys)
    this.updated({ scope: keys })
  }

  setObjectSilent(objects) {
    for (let key in objects) {
      let value = objects[key]
      if (value !== undefined)
        this.objects[key] = value
      else
        delete this.objects[key]
    }
  }

  setObject(objects) {
    this.setObjectSilent(objects)
    this.updated({ scope: Object.keys(objects) })
  }

  getObject(key) {
    return this.objects[key]
  }

  getCollectionData(key) {
    let collection = this.collections[key]
    return collection ? collection.data : null
  }
}

_.extend(AppState.prototype, Backbone.Events)
