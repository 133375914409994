import { Route, Switch, Redirect } from 'react-router-dom'
import { Modal, withErrorBoundary, Formatter } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import Chat        from './Chat'

function ChatModal({ base }) {
  return (
    <div className="ChatModal custom-modal">

      {/* <div className="modal-head">
        <div className="modal-titlebar">
          <h3 className="modal-title">Chat</h3>
          <div className="modal-titlebar-actions"></div>
        </div>
        <Modal.CloseButton />
      </div> */}

      <div className="modal-body">
        <Chat embedded={true} />
      </div>

    </div>
  )
}

export default withErrorBoundary(ChatModal)
