import ApiModel from 'base/api-model'

export default class Menu extends ApiModel
  @prop 'uid', -> "menu:#{this.id}"
  @prop 'route', ->
    mapping = RouteMapping[@id]
    return mapping if mapping
    return "menu/#{@id}" if @custom
    return null

  @prop 'custom', -> Number(@id) > 0
  @prop 'valid', -> @route != null
  @prop 'unsupported', -> false
  @prop 'nameText', -> @name

  @cprop 'defaultMenu', ->
    allMenus = application.state.getCollectionData('menus:load')
    menuId = if allMenus then allMenus[0] else null
    if menuId then application.state.getModel(menuId) else null

RouteMapping =
  my_feed: 'home',
  community_calendar: 'community',
  upcoming_events: 'menu/upcoming',
  parks: 'parks',
  clubs: 'clubs',
  fitness: 'menu/fitness',
  programming: 'menu/programming',
  neighbors: 'people',
  saved_amenities: 'menu/saved_amenities',
