import { withErrorBoundary, Formatter } from 'shared'
import { SmartButtonStub } from 'ui/smart-buttons'
import { LogoImage } from 'customizations'

class DownloadsPage extends Component
  state: {}

  componentDidMount: ->
    @loadData()

  loadData: ->
    @setState settings: await Backend.get('/users/signup_links')

  render: ->
    settings = @state.settings || {}

    pageTitle = settings.page_title
    backgroundColor = settings.color ? '#ccc'

    <div className="Login Signup Downloads">
      <div className="left d-none d-sm-block">
        <div className="left-overlay"></div>
        <p className="login-copyright">{application.assets.get('copyright')}</p>
      </div>
      <div className="right">
        <img src={LogoImage} className='logo-image mb-2' />
        <section className="form">
          <h3 className="mb-3 d-none">{pageTitle}</h3>

          <p dangerouslySetInnerHTML={ __html: Formatter.simpleFormat(settings.welcome_text) }></p>
          <div className="form-actions text-center app-downloads-links">
            <a className="smart-button btn inverted primary" href={settings.appstore_link} target="_blank" style={{marginBottom: '5px'}}>
              <span className="smart-button-text">Download for iOS</span>
            </a>

            <a className="smart-button btn inverted primary" href={settings.playstore_link} target="_blank" style={{marginBottom: '5px'}}>
              <span className="smart-button-text">Download for Android</span>
            </a>
          </div>

        </section>
      </div>
    </div>

export default withErrorBoundary(DownloadsPage)
