import { Api } from 'base'
import Announcement from './announcement'

class AnnouncementApi extends Api
  collectionName: 'announcements'

  index: ->
    response = await Backend.get '/announcements'
    _.map response.announcements, (r) => new Announcement(r)

export default new AnnouncementApi
