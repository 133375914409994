import { Api, ApiModel } from 'base'

class PaymentApi extends Api {
  collectionName = 'payments'

  async perform({ id }, paymentForParams) {
    let params = { smart_button_id: id }
    if(paymentForParams)
      params = {...params, ...paymentForParams}
    let result = await Backend.post(`/payments`, params)
    return new Payment(result.payment)
  }
}

export class Payment extends ApiModel {
  get succeeded() { return this.status == "succeeded" }
}

export default new PaymentApi
