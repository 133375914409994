import { Api } from 'base'
import SmartButton from './smart-button'
import SmartResponse from './smart-response'
import ChatMessage from './chat-message'

class SmartButtonApi extends Api {
  collectionName = 'smart_buttons'

  index(params, success) {
    return Backend.get('/smart_buttons', params, {
      success: json => {
        let buttons = json.smart_buttons.filter(button => {
          if (button.type == "smart-button:augmented-reality") return false
          if (button.type == "smart-button:guest-experience" && App.forceGuestMode) return false
          return true
        })
        return success(buttons.map(r => new SmartButton(r)))
      }
    })
  }

  forEvent(event, success) {
    return this.index({scope: 'Event', scope_object_id: event.id}, success)
  }

  forPark(park, date, success) {
    return this.index({scope: 'Place', scope_object_id: park.id, date: date && date.format("YYYY-MM-DD")}, success)
  }

  forClub(club, success) {
    return this.index({scope: 'Club', scope_object_id: club.id}, success)
  }

  forRetail(retail, success) {
    return this.index({scope: 'RetailAndCommercial', scope_object_id: retail.id}, success)
  }

  forFeed(success) {
    return this.index({scope: 'Feed'}, success)
  }

  forUsers(success) {
    return this.index({scope: 'Users'}, success)
  }

  forUser(success) {
    return this.index({scope: 'User'}, success)
  }

  async runServerAction(button) {
    let response = await Backend.post(`/smart_buttons/run/${button.action}`, button.params)
    return new SmartResponse({ ...response, userButton: button })
  }
}

export default new SmartButtonApi
