import { MissingImage } from 'customizations'
import { Modal, withErrorBoundary, GoogleStaticMap, Formatter } from 'shared'
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'

export default Announcements = ({announcements, base}) ->
  <div className="Announcements">
    {
      for announcement in Array.from(announcements)
        <Announcement announcement={announcement} base={base} key={announcement.id} />
    }
  </div>

Announcement = ({announcement, base}) ->
  <div className="Announcement">
    <b>{announcement.title}</b>
    <div className="my-2 body" dangerouslySetInnerHTML={{__html: Formatter.simpleFormat announcement.text}}></div>
    {
      if announcement.new_announcement
        <div className="home-chat-link">
          <SmartButtonLink text={announcement.target_name} className="admin-purple inverted primary" to="/home/chat" />
        </div>
      else
        announcement.target_arn &&
        <div className="">
          <SmartButtonLink text={announcement.target_name} className="admin-purple inverted primary" to={"#{base}/#{announcement.target_path}"} />
        </div>
    }
  </div>
